//import urls from "../api/urls";
// Returns the URl of a customer, given a UUID
const URL_CUSTOMER_DETAIL = `/customers/:guid`;
export function getCustomerUrl(uuid) {
  if (!uuid) {
    return null;
  }
  const url = URL_CUSTOMER_DETAIL.replace(":guid", uuid);
  return url;
}
const URL_CHECK_INS_UPDATE = `/checkins/:checkinUuid/update`;
export function getCheckinUpdateUrl(uuid) {
  if (!uuid) {
    return null;
  }
  const url = URL_CHECK_INS_UPDATE.replace(":checkinUuid", uuid);
  return url;
}
const URL_CHECK_INS_DETAIL = `/checkins/:checkinUuid`;
export function getCheckinDetailUrl(uuid) {
  if (!uuid) {
    return null;
  }
  const url = URL_CHECK_INS_DETAIL.replace(":checkinUuid", uuid);
  return url;
}
const URL_CUSTOMER_CHECK_INS_LIST = `/customers/:customerUuid/checkins`;
export function getCustomerCheckinsListUrl(uuid) {
  if (!uuid) {
    return null;
  }
  const url = URL_CUSTOMER_CHECK_INS_LIST.replace(":customerUuid", uuid);
  return url;
}
const URL_INVOICE_DETAIL = `/invoices/:stripeInvoiceId/subscriptions/:subscriptionUuid`;
export function getInvoiceDetailUrl(stripeInvoiceId, subscriptionUuid) {
  if (!stripeInvoiceId || !subscriptionUuid) {
    return null;
  }
  let p = URL_INVOICE_DETAIL.replace(":stripeInvoiceId", stripeInvoiceId);
  let url = p.replace(":subscriptionUuid", subscriptionUuid);
  return url;
}
const URL_SUBSCRIPTION_UPDATE = `/subscriptions/:subscriptionUuid/update`;
export function getSubscriptionUpdateUrl(uuid) {
  if (!uuid) {
    return null;
  }
  const url = URL_SUBSCRIPTION_UPDATE.replace(":subscriptionUuid", uuid);
  return url;
}
const URL_PLAN_DETAIL = `/plans/:guid`;
export function getPlanUrl(uuid) {
  if (!uuid) {
    return null;
  }
  const url = URL_PLAN_DETAIL.replace(":guid", uuid);
  return url;
}
const URL_SUBSCRIPTION_DETAIL_V1 = `/subscriptions/detail/:guid`;
const URL_SUBSCRIPTION_DETAIL = `/subscriptions/:guid`;
export function getSubscriptionDetailUrl(uuid) {
  if (!uuid) {
    return null;
  }
  const url = URL_SUBSCRIPTION_DETAIL.replace(":guid", uuid);
  return url;
}

const URL_SUBSCRIPTION_CHECK_INS_LIST = `/subscriptions/:subscriptionUuid/checkins`;
export function getSubscriptionCheckinsListUrl(uuid) {
  if (!uuid) {
    return null;
  }
  const url = URL_SUBSCRIPTION_CHECK_INS_LIST.replace(
    ":subscriptionUuid",
    uuid
  );
  return url;
}
const URL_SUBSCRIPTION_INVOICES_LIST = `/subscriptions/:subscriptionUuid/payments`;
export function getSubscriptionInvoicesListUrl(uuid) {
  if (!uuid) {
    return null;
  }
  const url = URL_SUBSCRIPTION_INVOICES_LIST.replace(":subscriptionUuid", uuid);
  return url;
}
const URL_CUSTOMER_CHECK_IN = `/checkins/new/:guid`;
export function getCustomerNewCheckInUrl(uuid) {
  if (!uuid) {
    return null;
  }
  const url = URL_CUSTOMER_CHECK_IN.replace(":guid", uuid);
  return url;
}
const URL_CUSTOMER_SUBSCRIPTIONS_LIST = `/customers/:customerUuid/subscriptions`;
export function getCustomerSubscriptionListUrl(uuid) {
  if (!uuid) {
    return null;
  }
  const url = URL_CUSTOMER_SUBSCRIPTIONS_LIST.replace(":customerUuid", uuid);
  return url;
}

const URL_CREDIT_DETAIL_V1 = `/credit/detail/:creditUuid`;
const URL_CREDIT_DETAIL = `/credit/:creditUuid`;
export function getCreditDetailUrl(uuid) {
  if (!uuid) {
    return null;
  }
  const url = URL_CREDIT_DETAIL.replace(":creditUuid", uuid);

  return url;
}

const URL_CREDIT_CHECK_INS_LIST = `/credits/:creditUuid/checkins`;
export function getCreditCheckinsListUrl(uuid) {
  if (!uuid) {
    return null;
  }
  const url = URL_CREDIT_CHECK_INS_LIST.replace(":creditUuid", uuid);
  return url;
}
const URL_CREDIT_UPDATE = `/credits/:creditUuid/update`;
export function getCreditUpdateUrl(uuid) {
  if (!uuid) {
    return null;
  }
  const url = URL_CREDIT_UPDATE.replace(":creditUuid", uuid);
  return url;
}
const URL_CREDIT_INSERT = `/credits/new/:customerUuid`;
export function getCreditInsertUrl(uuid) {
  if (!uuid) {
    return null;
  }
  const url = URL_CREDIT_INSERT.replace(":customerUuid", uuid);
  return url;
}
const URL_CUSTOMER_UPDATE = `/customers/:customerUuid/update`;
export function getCustomerUpdateUrl(uuid) {
  if (!uuid) {
    return null;
  }
  const url = URL_CUSTOMER_UPDATE.replace(":customerUuid", uuid);
  return url;
}

const URL_CREATE_RECEIPT = `/receipts/new/:checkinUuid`;
export function getCreateReceiptstUrl(uuid) {
  if (!uuid) {
    return null;
  }
  const url = URL_CREATE_RECEIPT.replace(":checkinUuid", uuid);
  return url;
}

const URL_RECEIPT_DETAIL_V1 = `/receipt/detail/:receiptUuid`;
const URL_RECEIPT_DETAIL = `/receipts/:receiptUuid`;
export function getReceiptDetailUrl(uuid) {
  if (!uuid) {
    return null;
  }
  const url = URL_RECEIPT_DETAIL.replace(":receiptUuid", uuid);

  return url;
}

const URL_RECEIPT_UPDATE = `/receipts/:receiptUuid/update`;
export function getReceiptUpdateUrl(uuid) {
  if (!uuid) {
    return null;
  }
  const url = URL_RECEIPT_UPDATE.replace(":receiptUuid", uuid);

  return url;
}
const URL_PREPAID_PACKAGE_DETAIL = `/prepaid_packages/:prepaidPackageUuid`;
export function getPrepaidPackageDetailUrl(uuid) {
  if (!uuid) {
    return null;
  }
  const url = URL_PREPAID_PACKAGE_DETAIL.replace(":prepaidPackageUuid", uuid);

  return url;
}
const URL_PREPAID_PACKAGE_UPDATE = `/prepaid_packages/:prepaidPackageUuid/update`;
export function getPrepaidPackageUpdateUrl(uuid) {
  if (!uuid) {
    return null;
  }
  const url = URL_PREPAID_PACKAGE_UPDATE.replace(":prepaidPackageUuid", uuid);
  return url;
}
const URL_PREPAID_PACKAGE_CHECK_INS_LIST = `/prepaid_packages/:prepaidPackageUuid/checkins`;
export function getPrepaidPackageCheckinsListUrl(uuid) {
  if (!uuid) {
    return null;
  }
  const url = URL_PREPAID_PACKAGE_CHECK_INS_LIST.replace(
    ":prepaidPackageUuid",
    uuid
  );
  return url;
}
const URL_SALES_LEDGER_REPORT = `/sales_ledger-report/:paramDate?`;
export function getSalesLedgerReportUrl(paramDate = null) {
  let url = URL_SALES_LEDGER_REPORT.replace(":paramDate?", "");
  if (paramDate) {
    url = URL_SALES_LEDGER_REPORT.replace(":paramDate?", paramDate);
  }
  return url;
}
//------------------------------------
// Stripe URL
const URL_STRIPE_CUSTOMER = "https://dashboard.stripe.com/customers/";
export function getCustomerStripeUrl(customerId) {
  if (!customerId) {
    return null;
  }
  const url = `${URL_STRIPE_CUSTOMER}${customerId}`;
  return url;
}
const URL_FC_CUSTOMER = "https://secure.fattureincloud.it/clients-analysis-";
export function getCustomerFcUrl(customerId) {
  if (!customerId) {
    return null;
  }
  const url = `${URL_FC_CUSTOMER}${customerId}`;
  return url;
}
const URL_STRIPE_SUBSCRIPTION = "https://dashboard.stripe.com/subscriptions/";
export function getSubscriptionStripeUrl(subscriptionId) {
  if (!subscriptionId) {
    return null;
  }
  const url = `${URL_STRIPE_SUBSCRIPTION}${subscriptionId}`;
  return url;
}
const URL_STRIPE_INVOICE = "https://dashboard.stripe.com//invoices/";
export function getInvoiceStripeUrl(invoiceId) {
  if (!invoiceId) {
    return null;
  }
  const url = `${URL_STRIPE_INVOICE}${invoiceId}`;
  return url;
}

//------------------------------------
// Document URL per la visualizzazione dei documenti

export function getDocumentUrl(document_url) {
  if (!document_url) {
    return null;
  }
  const url = `${document_url}`;
  return url;
}

const URL_LEADS = `/leads`;
const URL_SINGLE_LEAD = `/leads/:leadUuid`;
const URL_LIST_CREDIT = `/credits`;
const URL_LIST_PLAN = `/plans`;
const URL_SUBSCRIPTIONS_UNPAID = `/subscriptions/unpaid`;
const URL_LIST_SUBSCRIPTION = `/subscriptions`;
const URL_SUBSCRIPTIONS_SCHEDULED = `/subscriptions/scheduled`;
const URL_CUSTOMERS_SEARCH = `/customers/search`;
const URL_LIST_CHECK_IN = `/checkins`;
const URL_INVOICES_UNPAID = `/invoices/unpaid`;
const URL_SUBSCRIPTION_NEW_CONFIRMATION = `/subscriptions/new/confirmation`;
const URL_SUBSCRIPTION_NEW = `/subscriptions/new`;
const URL_SUBSCRIPTION_NEW_V3 = `/subscriptions/new/v3`;
const URL_SUBSCRIPTION_NEW_V2 = `/subscriptions/new/v2`;
const URL_SUBSCRIPTION_NEW_V1 = `/subscriptions/new/v1`;
const URL_LOGIN_PATH = "/login";
const URL_LOGOUT_PATH = "/logout";
const URL_SUMUP_CALLBACK = `/sumup`;
const URL_CHECKIN_LIST_OPEN = `/checkins/open`;
const URL_SALES_LEDGER = `/sales_ledger-report`;

const exportedConstants = {
  URL_CREATE_RECEIPT,
  URL_RECEIPT_DETAIL,
  URL_RECEIPT_DETAIL_V1,
  URL_RECEIPT_UPDATE,
  URL_CREDIT_INSERT,
  URL_CREDIT_UPDATE,
  URL_CREDIT_CHECK_INS_LIST,
  URL_CREDIT_DETAIL,
  URL_CREDIT_DETAIL_V1,
  URL_LIST_CREDIT,
  URL_CUSTOMER_CHECK_IN,
  URL_PLAN_DETAIL,
  URL_SUBSCRIPTION_DETAIL,
  URL_SUBSCRIPTION_DETAIL_V1,
  URL_CUSTOMER_DETAIL,
  URL_LIST_PLAN,
  URL_SUBSCRIPTIONS_UNPAID,
  URL_LIST_SUBSCRIPTION,
  URL_SUBSCRIPTIONS_SCHEDULED,
  URL_LIST_CHECK_IN,
  URL_INVOICES_UNPAID,
  URL_CHECK_INS_UPDATE,
  URL_CHECK_INS_DETAIL,
  URL_CUSTOMER_CHECK_INS_LIST,
  URL_CUSTOMER_SUBSCRIPTIONS_LIST,
  URL_SUBSCRIPTION_CHECK_INS_LIST,
  URL_SUBSCRIPTION_INVOICES_LIST,
  URL_INVOICE_DETAIL,
  URL_SUBSCRIPTION_UPDATE,
  URL_CUSTOMER_UPDATE,
  URL_SUBSCRIPTION_NEW_CONFIRMATION,
  URL_SUBSCRIPTION_NEW,
  URL_SUBSCRIPTION_NEW_V1,
  URL_SUBSCRIPTION_NEW_V2,
  URL_SUBSCRIPTION_NEW_V3,
  URL_LOGIN_PATH,
  URL_LOGOUT_PATH,
  URL_LEADS,
  URL_SINGLE_LEAD,
  URL_SUMUP_CALLBACK,
  URL_CHECKIN_LIST_OPEN,
  URL_PREPAID_PACKAGE_DETAIL,
  URL_PREPAID_PACKAGE_UPDATE,
  URL_PREPAID_PACKAGE_CHECK_INS_LIST,
  URL_SALES_LEDGER_REPORT,
  URL_SALES_LEDGER,
  URL_CUSTOMERS_SEARCH,
};

export default exportedConstants;
