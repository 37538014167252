import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { gql, useLazyQuery } from '@apollo/client';
import Alert from "react-bootstrap/Alert";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import CustomerCreateModal from "./customer/CustomerCreateModal";
import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender,
} from '@tanstack/react-table';

const CUSTOMER_ADVANCED_SEARCH = gql`
  query CustomerAdvancedSearch($searchInput: CustomerSearchInput!) {
    customerAdvancedSearch(searchInput: $searchInput) {
      uuid
      first
      last
      taxId
      email
      phoneNumber
      dob
      fullName
      location {
        name
        uuid
      }
    }
  }
`;

const fuzzyFilter = (row, columnId, value) => {
  const searchValue = value.toLowerCase();
  const cellValue = String(row.getValue(columnId) || '').toLowerCase();
  
  return cellValue.includes(searchValue);
};

function CustomerSearchForm({
  data: initialData,
  columns,
  openCustomer,
  onCreatedCustomer,
}) {
  const [formData, setFormData] = useState({
    first: '',
    last: '',
    taxId: '',
    email: '',
    phoneNumber: '',
    dob: ''
  });

  const MAX_RECORDS = 100;
  const [searchCustomers, { loading, error }] = useLazyQuery(CUSTOMER_ADVANCED_SEARCH, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data && data.customerAdvancedSearch) {
        setTableData(data.customerAdvancedSearch);
      }
    }
  });

  const [modalShow, setModalShow] = useState(false);
  const [tableData, setTableData] = useState(initialData);
  const [clickTimer, setClickTimer] = useState(null);
  const [validationError, setValidationError] = useState('');
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);

  const table = useReactTable({
    data: tableData,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      columnFilters,
      globalFilter,
      sorting,
      pagination: {
        pageSize: MAX_RECORDS,
        pageIndex: 0,
      },
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onSortingChange: setSorting,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    initialState: {
      pagination: {
        pageSize: MAX_RECORDS,
      },
    },
  });

  // Handle form field changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    
    // If the field is not empty and has less than 3 characters, show an error message
    if (name !== 'dob' && value && value.length < 2) {
      e.target.setCustomValidity('Inserire almeno 2 caratteri');
    } else {
      e.target.setCustomValidity('');
    }
    
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  // Handle form submission
  const handleSearch = (e) => {
    e.preventDefault();

    // Verifica la lunghezza minima per i campi testuali
    const fieldsToValidate = {
      first: formData.first,
      last: formData.last,
      taxId: formData.taxId,
      email: formData.email,
      phoneNumber: formData.phoneNumber
    };

    // Verifica se almeno un campo ha 2 o più caratteri
    const hasValidField = Object.entries(fieldsToValidate).some(([key, value]) => {
      return value && value.length >= 2;
    });

    if (!hasValidField && !formData.dob) {
      setValidationError("Inserire almeno 2 caratteri in uno dei campi di ricerca o selezionare una data");
      return;
    }
    setValidationError('');

    const searchInput = {
      first: formData.first || null,
      last: formData.last || null,
      taxId: formData.taxId || null,
      email: formData.email || null,
      phoneNumber: formData.phoneNumber || null,
      dob: formData.dob || null
    };

    searchCustomers({
      variables: { searchInput }
    });
  };

  // Add this function to handle reset
  const handleReset = () => {
    // Reset form
    setFormData({
      first: '',
      last: '',
      taxId: '',
      email: '',
      phoneNumber: '',
      dob: ''
    });
    
    // Reset table data
    setTableData([]);
    
    // Reset error message
    setValidationError('');
    
    // Reset column filters
    setColumnFilters([]);
    setGlobalFilter('');
    
    // Reset column filters in table
    table.resetColumnFilters();
  };

  // Function to handle click
  const handleClick = (customer) => {
    if (clickTimer === null) {
      setClickTimer(
        setTimeout(() => {
          openCustomer(customer, false);
          setClickTimer(null);
        }, 450)
      );
    }
  };

  // Function to handle double click
  const handleDoubleClick = (customer) => {
    if (clickTimer) {
      clearTimeout(clickTimer);
      setClickTimer(null);
    }
    openCustomer(customer, true);
  };

  return (
    <>
      <Row>
        <Col xl={6}>
          <h3>Ricerca Clienti</h3>
        </Col>
        <Col xl={6} className="text-end">
          <Button variant="primary" onClick={() => setModalShow(true)}>
            Inserisci nuovo cliente
          </Button>
        </Col>
      </Row>

      <Form onSubmit={handleSearch}>
        <Row className="mb-3">
        <Col md={4}>
            <Form.Group>
              <Form.Label>Cognome</Form.Label>
              <Form.Control 
                type="text" 
                name="last"
                value={formData.last}
                onChange={handleInputChange}
                placeholder="Inserisci il cognome"
                minLength="2"
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group>
              <Form.Label>Nome</Form.Label>
              <Form.Control 
                type="text" 
                name="first"
                value={formData.first}
                onChange={handleInputChange}
                placeholder="Inserisci il nome"
                minLength="2"
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group>
              <Form.Label>Email</Form.Label>
              <Form.Control 
                type="text" 
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                placeholder="Inserisci l'email"
                minLength="2"
              />
            </Form.Group>
          </Col>

        </Row>
        <Row className="mb-3">
        <Col md={4}>
            <Form.Group>
              <Form.Label>Codice Fiscale</Form.Label>
              <Form.Control 
                type="text" 
                name="taxId"
                value={formData.taxId}
                onChange={handleInputChange}
                placeholder="Inserisci il codice fiscale"
                minLength="3"
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group>
              <Form.Label>Telefono</Form.Label>
              <Form.Control 
                type="text" 
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleInputChange}
                placeholder="Inserisci il numero di telefono"
                minLength="3"
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group>
              <Form.Label>Data di Nascita</Form.Label>
              <Form.Control 
                type="date" 
                name="dob"
                value={formData.dob}
                onChange={handleInputChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col className="d-flex justify-content-between">
            <div>
              <Button 
                variant="primary" 
                type="submit"
                disabled={loading}
                className="me-2"
              >
                {loading ? 'Ricerca in corso...' : 'Cerca Cliente'}
              </Button>
            </div>
            <div>
              {tableData && tableData.length > 0 && (
                <Button 
                  variant="primary" 
                  onClick={handleReset}
                >
                  Nuova ricerca
                </Button>
              )}
            </div>
          </Col>
        </Row>

        {validationError && (
          <Row className="mb-3">
            <Col>
              <Alert variant="danger">
                {validationError}
              </Alert>
            </Col>
          </Row>
        )}

        {error && (
          <Alert variant="danger">
            Errore durante la ricerca: {error.message}
          </Alert>
        )}
      </Form>

      <div className="mt-4">
        <Row>
          <Col>
            {tableData && tableData.length > 0 && (
              <div className="table-responsive">
                <table className="table table-striped table-bordered table-hover">
                  <thead>
                    {table.getHeaderGroups().map(headerGroup => (
                      <tr key={headerGroup.id}>
                        {headerGroup.headers.map(header => (
                          <th key={header.id}>
                            {header.column.getCanSort() ? (
                              <div
                                {...{
                                  className: 'cursor-pointer select-none',
                                  onClick: header.column.getToggleSortingHandler(),
                                }}
                              >
                                {flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                                {{
                                  asc: ' 🔼',
                                  desc: ' 🔽',
                                }[header.column.getIsSorted()] ?? null}
                              </div>
                            ) : (
                              flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )
                            )}
                            {header.column.getCanFilter() ? (
                              <div>
                                <input
                                  type="text"
                                  className="form-control form-control-sm"
                                  value={header.column.getFilterValue() ?? ''}
                                  onChange={e =>
                                    header.column.setFilterValue(e.target.value)
                                  }
                                  placeholder={`Filtra per ${header.column.columnDef.header}`}
                                />
                              </div>
                            ) : null}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody>
                    {table.getRowModel().rows.slice(0, MAX_RECORDS).map(row => (
                      <tr
                        key={row.id}
                        onClick={() => handleClick(row.original)}
                        onDoubleClick={() => handleDoubleClick(row.original)}
                        style={{ cursor: 'pointer' }}
                        title="Click pervisualizzare i dati del Cliente. Doppio Click per visualizzare i dati del Cliente in una nuova scheda del browser"
                      >
                        {row.getVisibleCells().map(cell => (
                          <td key={cell.id}>
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                  {tableData.length > MAX_RECORDS && (
                    <tfoot>
                      <tr>
                        <td colSpan={table.getAllColumns().length} className="text-center">
                          Sono stati trovati molti risultati. Vengono mostrati solo i primi {MAX_RECORDS} record.
                        </td>
                      </tr>
                    </tfoot>
                  )}
                </table>
              </div>
            )}
          </Col>
        </Row>
      </div>

      <CustomerCreateModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        onCreated={onCreatedCustomer}
      />
    </>
  );
}

export default CustomerSearchForm;
