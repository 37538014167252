import { Badge, Form, ListGroup } from "react-bootstrap";
import React, { useEffect, useRef, useState } from "react";

import { gql } from "@apollo/client";
import styled from "@emotion/styled";
import { useLazyQuery } from "@apollo/client";

// Constants
const KEYS = {
  TAB: "Tab",
  ENTER: "Enter",
  BACKSPACE: "Backspace",
  ARROW_UP: "ArrowUp",
  ARROW_DOWN: "ArrowDown",
  COMMA: ",",
  SPACE: " ",
};

const DEFAULT_SEPARATORS = [KEYS.ENTER, KEYS.COMMA, KEYS.SPACE, KEYS.TAB];
const MIN_SEARCH_LENGTH = 3;

// Add GraphQL query
const SEARCH_TAGS = gql`
  query SearchTags($query: String!) {
    searchTags(searchQuery: $query) {
      id
      name
    }
  }
`;

const TagsContainer = styled.div`
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
  padding: 0.375rem 0.75rem;
  min-height: 38px;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  align-items: center;

  &:focus-within {
    border-color: #86b7fe;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  }
`;

const InputContainer = styled.div`
  flex: 1;
  min-width: 100px;
`;

const SuggestionsWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const TagsComponent = ({
  tags = [],
  onTagsChange,
  placeholder = "Add tags...",
  separators = DEFAULT_SEPARATORS,
  allowDuplicates = false,
  readOnly = false,
  disableTagCreation = false,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [activeSuggestion, setActiveSuggestion] = useState(-1);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const inputRef = useRef(null);
  const suggestionsRef = useRef(null);

  // Add GraphQL query hook
  const [searchTags, { data: searchData }] = useLazyQuery(SEARCH_TAGS, {
    fetchPolicy: "network-only",
  });

  const filterSuggestions = (value) => {
    if (!value.trim()) return [];

    const searchResults = searchData?.searchTags || [];
    return searchResults.filter(
      (tag) =>
        !tags.some((existingTag) => existingTag.id === tag.id) ||
        allowDuplicates
    );
  };

  const addTag = (value) => {
    // If value is already a tag object, add it directly
    if (typeof value === "object" && value.id && value.name) {
      if (!allowDuplicates && tags.some((tag) => tag.id === value.id)) return;

      onTagsChange([...tags, value]);
      setInputValue("");
      setShowSuggestions(false);
      setActiveSuggestion(-1);
      return;
    }

    // Don't allow creating new tags if disabled
    if (disableTagCreation) return;

    // Handle string input (if tag creation is allowed)
    const trimmedValue = value.trim();
    if (!trimmedValue) return;

    // Check if a matching suggestion exists
    const matchingSuggestion = suggestions.find(
      (s) => s.name.toLowerCase() === trimmedValue.toLowerCase()
    );

    if (matchingSuggestion) {
      addTag(matchingSuggestion);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === KEYS.BACKSPACE && !inputValue && tags.length > 0) {
      e.preventDefault();
      onTagsChange(tags.slice(0, -1));
      return;
    }

    if (showSuggestions && suggestions.length > 0) {
      if (e.key === KEYS.ARROW_DOWN) {
        e.preventDefault();
        setActiveSuggestion((prev) =>
          prev < suggestions.length - 1 ? prev + 1 : prev
        );
      } else if (e.key === KEYS.ARROW_UP) {
        e.preventDefault();
        setActiveSuggestion((prev) => (prev > 0 ? prev - 1 : -1));
      } else if (e.key === KEYS.ENTER && activeSuggestion >= 0) {
        e.preventDefault();
        addTag(suggestions[activeSuggestion]);
        return;
      }
    }

    if (separators.includes(e.key)) {
      e.preventDefault();
      const value = inputValue.replace(KEYS.COMMA, "");
      addTag(value);

      if (e.key === KEYS.TAB) {
        e.preventDefault();
      }
    }
  };

  // Update handleInputChange to trigger search
  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);

    if (value.trim().length >= MIN_SEARCH_LENGTH) {
      searchTags({ variables: { query: value } });
      const filtered = filterSuggestions(value);
      setSuggestions(filtered);
      setShowSuggestions(true);
    } else {
      setSuggestions([]);
      setShowSuggestions(false);
    }
    setActiveSuggestion(-1);
  };

  const handleDelete = (tagToDelete) => {
    onTagsChange(tags.filter((tag) => tag.id !== tagToDelete.id));
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        suggestionsRef.current &&
        !suggestionsRef.current.contains(event.target)
      ) {
        setShowSuggestions(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <Form.Group className="mb-3">
      <TagsContainer>
        {tags.map((tag) => (
          <Badge
            key={tag.id}
            bg="secondary"
            className="d-flex align-items-center"
          >
            {tag.name}
            {!readOnly && (
              <span
                role="button"
                className="ms-2"
                onClick={() => handleDelete(tag)}
                style={{ cursor: "pointer" }}
              >
                ×
              </span>
            )}
          </Badge>
        ))}
        {!readOnly && (
          <InputContainer>
            <Form.Control
              ref={inputRef}
              type="text"
              value={inputValue}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              onFocus={() => inputValue.trim() && setShowSuggestions(true)}
              placeholder={tags.length === 0 ? placeholder : ""}
              style={{
                border: "none",
                padding: 0,
                margin: 0,
                minHeight: "auto",
                height: "auto",
                boxShadow: "none",
              }}
            />
          </InputContainer>
        )}
      </TagsContainer>

      {!readOnly && (
        <SuggestionsWrapper>
          {showSuggestions && suggestions.length > 0 && (
            <ListGroup
              ref={suggestionsRef}
              className="position-absolute w-100 shadow-sm"
              style={{ zIndex: 1000, maxHeight: "200px", overflowY: "auto" }}
            >
              {suggestions.map((suggestion, index) => (
                <ListGroup.Item
                  key={suggestion.id}
                  active={index === activeSuggestion}
                  action
                  onClick={() => addTag(suggestion)}
                  onMouseEnter={() => setActiveSuggestion(index)}
                >
                  {suggestion.name}
                </ListGroup.Item>
              ))}
            </ListGroup>
          )}
        </SuggestionsWrapper>
      )}
    </Form.Group>
  );
};

export default TagsComponent;
