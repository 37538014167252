import React, { useContext } from "react";

import Container from "react-bootstrap/Container";
//import Auth from "../../api/auth";
import { LOCATION_DROPDOWN_DATA, GET_USER_SETTING } from "../../api/queries";
import LocationSelectDropdown from "./LocationSelectDropdown";
import Nav from "react-bootstrap/Nav";
import { NavBarSearch } from "./NavBarSearch";
import NavDropdown from "react-bootstrap/NavDropdown";
import NavUser from "./NavUser";
import Navbar from "react-bootstrap/Navbar";
//import actions from "../../actions/auth";
import { store } from "../../store";
import styled from "@emotion/styled";
import urls from "../../api/urls";
import { useQuery } from "@apollo/client";
import { isUserInGroup } from "../../api/utils";
import constants from "../../constants";

const METABASE_BASE_URL =
  "https://stats.medextetica.com/public/dashboard/f3bb5a11-71f2-44a3-b76a-99a10575c793";

// const SCHEDULING_URL = "https://essential-pz.as.me/";
const VERIFY_TAXID =
  "https://telematici.agenziaentrate.gov.it/VerificaCF/Scegli.do?parameter=verificaCf";

const NavBarElement = styled.div`
  margin-right: 0.5rem;
  margin-bottom: 0.1rem;
  margin-top: 0.1rem;
`;

const LeadModuleIsActive = true;

function StatsLink() {
  const { data } = useQuery(LOCATION_DROPDOWN_DATA);
  const metabaseUrl = new URL(METABASE_BASE_URL);
  if (data) {
    const currentLocation = data["userSetting"]["currentLocation"];
    metabaseUrl.searchParams.set("sede", currentLocation?.name);
  }
  return (
    <Nav.Link href={metabaseUrl.toString()} target="blank">
      Statistiche
    </Nav.Link>
  );
}

function CorrispettiviLink() {
  const { data } = useQuery(GET_USER_SETTING);

  // Use optional chaining to access data securely
  const isManagementCorrispettivi = isUserInGroup(
    data?.getUserSetting ?? {}, // Use null coalescing to handle the undefined/null case
    constants.GROUPS_MANAGEMENT_CORRISPETTIVI
  );

  // Return null directly if the condition is not satisfied
  if (!isManagementCorrispettivi) return null;

  // Return the NavDropdown only if isManagementCorrispettivi is true
  return (
    <NavDropdown title="Corrispettivi" id="Corrispettivi">
      <NavDropdown.Item href={urls.URL_SALES_LEDGER}>
        Registro Corrispettivi
      </NavDropdown.Item>
    </NavDropdown>
  );
}

function NavbarHeader() {
  const { state } = useContext(store);
  let leadsModule;

  // Add this function to handle the keyboard shortcut
  React.useEffect(() => {
    const handleKeyPress = (event) => {
      // Checks both metaKey (Command on Mac) and altKey (Alt on Windows)
      if ((event.metaKey || event.altKey) && event.key === 'r') {
        event.preventDefault();
        window.location.href = urls.URL_CUSTOMERS_SEARCH;
      }
    };

    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, []);


  // The component is not rendered if the user is logged out
  if (state.auth.loggedIn === false) {
    return null;
  }

  if (LeadModuleIsActive) {
    leadsModule = <Nav.Link href={urls.URL_LEADS}>Leads</Nav.Link>;
  }

  const content = (
    <>
      <Navbar bg="light" expand="lg">
        <Container fluid>
          <Navbar.Brand href="/">MedexPay</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto my-2 my-lg-0">
              <NavDropdown title="Abbonamenti" id="Abbonamenti">
                <NavDropdown.Item href={`${urls.URL_SUBSCRIPTION_NEW}`}>
                  Nuovo Abbonamento 🚀
                </NavDropdown.Item>
                <NavDropdown.Item href={urls.URL_LIST_SUBSCRIPTION}>
                  Elenco Abbonamenti
                </NavDropdown.Item>
                <NavDropdown.Item href={urls.URL_SUBSCRIPTIONS_UNPAID}>
                  Elenco Abbonamenti Insoluti
                </NavDropdown.Item>
                <NavDropdown.Item href={urls.URL_LIST_PLAN}>
                  Elenco Piani Pagamento
                </NavDropdown.Item>
                {/* <NavDropdown.Item href={urls.URL_SUBSCRIPTIONS_SCHEDULED}>
                  Elenco Abbonamenti Schedulati
                </NavDropdown.Item> */}
              </NavDropdown>
              <NavDropdown title="Clienti" id="Clienti">
                <NavDropdown.Item href={urls.URL_LIST_CHECK_IN}>
                  Elenco Check-In
                </NavDropdown.Item>
                <NavDropdown.Item href={urls.URL_CHECKIN_LIST_OPEN}>
                  Elenco Check-In Aperti
                </NavDropdown.Item>
                <NavDropdown.Item href={urls.URL_CUSTOMERS_SEARCH}>
                  Ricerca Avanzata Clienti (⌘ + R)
                </NavDropdown.Item>
                <NavDropdown.Item href={VERIFY_TAXID} target="blank">
                  Verifica Codice Fiscale
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title="Crediti" id="Crediti">
                <NavDropdown.Item href={urls.URL_LIST_CREDIT}>
                  Elenco Crediti
                </NavDropdown.Item>
              </NavDropdown>
              <CorrispettiviLink></CorrispettiviLink>
              <StatsLink />
              {leadsModule}
              <Nav.Link href="/logout">Logout</Nav.Link>
            </Nav>
            <Nav>
              <NavUser />
            </Nav>
            <Nav>
              <NavBarElement>
                <LocationSelectDropdown />
              </NavBarElement>
              <NavBarElement>
                <NavBarSearch></NavBarSearch>
              </NavBarElement>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
  return content;
}
export default NavbarHeader;
