import {
  subscriptionItalianState,
  subscriptionItalianStatePaid,
  isUserInGroup,
} from "../api/utils";
import {
  getCheckinDetailUrl,
  getCustomerNewCheckInUrl,
  getCustomerStripeUrl,
  getCustomerUrl,
  getDocumentUrl,
  getInvoiceDetailUrl,
  getPlanUrl,
  getSubscriptionStripeUrl,
  getSubscriptionUpdateUrl,
} from "../api/urls";

import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Col from "react-bootstrap/Col";
import { ContractLink } from "./ContractLink";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import ListGroup from "react-bootstrap/ListGroup";
import NotesDisplay from "./NotesDisplay";
import React from "react";
import Row from "react-bootstrap/Row";
import addBsArrowRight from "../components/AddBsArrowRight";
import constants from "../constants";
import dropDownHeaderView from "../components/DropDownHeaderView";

function getLinkCustomerStripe(customerId) {
  if (!customerId) return <></>;
  const item = (
    <Dropdown.Item href={getCustomerStripeUrl(customerId)} target="_blank">
      {addBsArrowRight("Cliente Stripe")}
    </Dropdown.Item>
  );
  return item;
}

function getLinkSubscriptionStripe(subscriptionId) {
  if (!subscriptionId) return <></>;
  const item = (
    <Dropdown.Item
      href={getSubscriptionStripeUrl(subscriptionId)}
      target="_blank"
    >
      {addBsArrowRight("Abbonamento Stripe")}
    </Dropdown.Item>
  );
  return item;
}

function SubscriptionDetailForm({
  data,
  payData,
  checkinList,
  counterCheckIn,
  counterCheckInNotPresent,
  invoiceCounterExpired,
  openSubscriptionCheckinsList,
  invoiceForSubscription,
  openSubscriptionInvoicesList,
  userSetting,
  onCancelDocument,
}) {
  const customerStripeId = data?.invoicingCustomer?.stripeId;
  const subscriptionStripeId = data?.stripeSubscriptionId;
  const currentLocation = userSetting?.currentLocation;
  const isSameLocation =
    currentLocation?.uuid === data?.location?.uuid ? true : false;

  let signContract = null;

  const isManagementStripeMenu = isUserInGroup(
    userSetting,
    constants.GROUPS_MANAGEMENT_STRIPE_MENU
  );

  const isDeleteSignedDocuments = isUserInGroup(
    userSetting,
    constants.GROUPS_DELETE_SIGNED_DOCUMNTS
  );

  if (
    !data.agreementContract &&
    data?.state === constants.SUBSCRIPTION_STATE_ACTIVE
  ) {
    const contractLinkParams = {
      signatureFormType: "NewContract",
      first: data?.invoicingCustomer?.first,
      last: data?.invoicingCustomer?.last,
      taxId: data?.invoicingCustomer?.taxId,
      email: data?.invoicingCustomer?.email,
      paymentType: data?.paymentType,
      locationName: data?.location.name,
      amount: data?.plan?.price,
      agreementContract: data?.plan?.agreementType?.signingDocumentUrl,
      subscriptionUuid: data?.uuid,
      invoicingCustomerUuid: data?.invoicingCustomer?.uuid,
    };
    signContract = (
      <ContractLink subscription={contractLinkParams}></ContractLink>
    );
  }

  if (
    !data?.cancellationContract &&
    data?.state === constants.SUBSCRIPTION_STATE_CANCELED
  ) {
    const contractLinkParams = {
      signatureFormType: "CancelContract",
      first: data?.invoicingCustomer?.first,
      last: data?.invoicingCustomer?.last,
      taxId: data?.invoicingCustomer?.taxId,
      email: data?.invoicingCustomer?.email,
      paymentType: data?.paymentType,
      locationName: data?.location.name,
      amount: data?.plan?.price,
      agreementContract: data?.plan?.cancelAgreementType?.signingDocumentUrl,
      subscriptionUuid: data?.uuid,
      startDate: data?.startDate,
      numberOfSession: counterCheckIn - counterCheckInNotPresent,
      typeSubscription: data?.plan?.name,
      invoicingCustomerUuid: data?.invoicingCustomer?.uuid,
    };
    signContract = (
      <ContractLink subscription={contractLinkParams}></ContractLink>
    );
  }

  const paySubscription = payData?.stripeSubscription?.result;

  //Calculate the number of installments paid
  const countInstallments = !!invoiceForSubscription
    ? invoiceForSubscription.reduce(function (n, item) {
        return n + (item.paidAt !== null);
      }, 0)
    : null;

  //
  // load Button for list all Check-Ins
  //
  let btnPlusCheckIn = null;
  if (checkinList?.length !== 0) {
    btnPlusCheckIn = (
      <Button
        variant="outline-info"
        size="sm"
        as="a"
        onClick={openSubscriptionCheckinsList}
        className="pr-2"
        key={"z3"}
      >
        Lista completa Check-In <Badge variant="info">{counterCheckIn}</Badge>
      </Button>
    );
  }
  //
  //load list check-ins
  //
  let checkinListGroupItems = null;
  if (checkinList?.length === 0) {
    checkinListGroupItems = (
      <ListGroup.Item disabled className="list-group-item-ck">
        Non ci sono check-in effettuati
      </ListGroup.Item>
    );
  } else {
    checkinList = checkinList.slice(0, 3);
    checkinListGroupItems = (
      <>
        {checkinList.map((s) => {
          return (
            <ListGroup.Item
              key={s.uuid}
              value={s.index}
              className="list-group-item-ck"
              action
              href={getCheckinDetailUrl(s.uuid)}
            >
              {s.checkInStatus}
              {s.checkInDate.substr(2)} | {s.checkInPlanShortName} |{" "}
              {s.checkInProductName}
            </ListGroup.Item>
          );
        })}
      </>
    );
  }
  //
  // load Button for list invoices
  //
  let btnPlusInvoices = null;
  let labelInvoices = "";
  if (invoiceForSubscription !== null) {
    if (invoiceForSubscription?.length !== 0) {
      labelInvoices = `Ultimi Pagamenti Effettuati`;
      btnPlusInvoices = (
        <>
          <Button
            variant="outline-info"
            size="sm"
            as="a"
            onClick={openSubscriptionInvoicesList}
            className="pr-2"
            key={"z4"}
          >
            Lista completa Pagamenti{" "}
            <Badge variant="info">{countInstallments}</Badge>
          </Button>
        </>
      );
    }
  }
  //
  //load list invoices
  //
  let invoicesListGroupItems = null;
  if (invoiceForSubscription !== null) {
    if (invoiceForSubscription?.length === 0) {
      invoicesListGroupItems = (
        <ListGroup className="list-group-ck">
          <ListGroup.Item disabled className="list-group-item-ck">
            Non ci sono fatture emesse
          </ListGroup.Item>
        </ListGroup>
      );
    } else {
      const invoicesList = invoiceForSubscription.slice(0, 3);
      invoicesListGroupItems = (
        <>
          <ListGroup className="list-group-ck">
            {invoicesList.map((s) => {
              const listGroup = (
                <ListGroup.Item
                  key={s.index}
                  value={s.index}
                  className="list-group-item-ck"
                  action
                  href={getInvoiceDetailUrl(s?.stripeInvoiceId, data.uuid)}
                >
                  {s.item}
                </ListGroup.Item>
              );
              return listGroup;
            })}
          </ListGroup>
        </>
      );
    }
  }

  let paymentType = data?.paymentType;

  if (data?.paymentType === constants.STRIPE_PAYMENT_METHOD) {
    const dataAvailable =
      !!payData &&
      !!payData?.invoiceForSubscription?.result &&
      !!payData?.stripeSubscription?.result;
    if (dataAvailable) {
      let pause_behavior =
        paySubscription?.pauseBehavior === null
          ? ""
          : `-- ${paySubscription?.pauseBehavior}`;
      paymentType = `${data?.paymentType} -- ${paySubscription?.status} ${pause_behavior}`;
    } else {
      paymentType = `${data?.paymentType} -- Query failed`;
    }
  }

  let dataChangeState = "";
  let labelDataChangeState = "";

  if (data?.state === constants.SUBSCRIPTION_STATE_CANCELED) {
    const changeState = !!data?.cancelDate ? data?.cancelDate : "";
    const insoluto = !!data.automaticDeletionForUnpaid ? "per Insolvenza" : "";
    if (!!changeState) {
      labelDataChangeState = `Stato: ${
        subscriptionItalianState[data?.state]
      } ${insoluto}`;
      dataChangeState = `in data ${data?.cancelDate}`;
    } else {
      labelDataChangeState = "Stato";
      dataChangeState = `${subscriptionItalianState[data?.state]} ${insoluto}`;
    }
  }

  if (data?.state === constants.SUBSCRIPTION_STATE_SCHEDULED) {
    labelDataChangeState = `Stato: ${subscriptionItalianState[data?.state]} `;
    dataChangeState = ` in data: ${data?.scheduledDate}`;
  }

  if (data?.state === constants.SUBSCRIPTION_STATE_ACTIVE) {
    labelDataChangeState = `Stato: ${subscriptionItalianState[data?.state]} `;
  }

  if (data?.state === constants.SUBSCRIPTION_STATE_PAUSED) {
    labelDataChangeState = `Stato: ${subscriptionItalianState[data?.state]} `;
    dataChangeState = "(Riscossione in Pausa)";
  }

  if (data?.state === constants.SUBSCRIPTION_STATE_PENDING) {
    labelDataChangeState = "Stato";
    dataChangeState = `${subscriptionItalianState[data?.state]} (Pending)`;
  }
  if (data?.state === constants.SUBSCRIPTION_STATE_NEEDS_AUTH) {
    labelDataChangeState = "Stato: Autorizzazione richiesta";
  }

  let dateStart = data?.startDate;
  dateStart =
    data?.paidState === constants.SUBSCRIPTION_STATEPAID_UNPAID
      ? `${dateStart} -- ${
          subscriptionItalianStatePaid[constants.SUBSCRIPTION_STATEPAID_UNPAID]
        }`
      : dateStart;

  const dropdownCustomer =
    data?.customer?.uuid === data?.invoicingCustomer?.uuid ? null : (
      <Dropdown.Item href={getCustomerUrl(data?.customer?.uuid)}>
        {addBsArrowRight("Beneficiario")}
      </Dropdown.Item>
    );

  // Visualizzazione Contratto
  let signedContract = "(Contratto firmato: No)";
  if (!!data.agreementContract) {
    signedContract = "(Contratto firmato: Si)";
    if (data?.agreementContract?.signedDocumentUrl) {
      signedContract = (
        <a
          href={getDocumentUrl(data?.agreementContract?.signedDocumentUrl)}
          target="blank"
          rel="noopener noreferrer"
        >
          (Contratto firmato: Si)
        </a>
      );
    }
  }
  // Visualizzazione Disdetta
  let signedCancelContract = null;
  if (data?.state === constants.SUBSCRIPTION_STATE_CANCELED) {
    signedCancelContract = "(Disdetta firmata: No)";
    if (!!data.cancellationContract) {
      signedCancelContract = "(Disdetta firmata: Si)";
      if (data?.cancellationContract?.signedDocumentUrl) {
        signedCancelContract = (
          <a
            href={getDocumentUrl(data?.cancellationContract?.signedDocumentUrl)}
            target="blank"
            rel="noopener noreferrer"
          >
            (Disdetta firmata: Si)
          </a>
        );
      }
    }
  }

  const cardLast4 = paySubscription?.paymentMethod?.paymentVisibleIdentifier;
  const shownCardLast4 = cardLast4 ? `•••• ${cardLast4}` : "Nessuna";

  return (
    <>
      <Row>
        <Col xs={8}>
          <h4 className="text-center"> Abbonamento </h4>
        </Col>
        <Col xs={4}>
          <div
            style={{
              textAlign: "right",
            }}
          >
            <ButtonGroup>
              <Col>{signContract}</Col>
              <DropdownButton
                menualign="right"
                title="Azioni"
                id="dropdown-menu-align-right"
                variant="outline-secondary"
              >
                <Dropdown.Item
                  href={getSubscriptionUpdateUrl(data?.uuid)}
                  disabled={!isSameLocation}
                >
                  {addBsArrowRight("Modifica Abbonamento")}
                </Dropdown.Item>
                <Dropdown.Item
                  href={getCustomerNewCheckInUrl(data?.customer?.uuid)}
                  disabled={!isSameLocation}
                >
                  {addBsArrowRight("Nuovo Check-In")}
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Header>{dropDownHeaderView()}</Dropdown.Header>
                <Dropdown.Item
                  href={getCustomerUrl(data?.invoicingCustomer?.uuid)}
                >
                  {addBsArrowRight("Titolare")}
                </Dropdown.Item>
                {dropdownCustomer}
                <Dropdown.Item href={getPlanUrl(data?.plan?.uuid)}>
                  {addBsArrowRight("Piano")}
                </Dropdown.Item>
                {isManagementStripeMenu
                  ? getLinkCustomerStripe(customerStripeId)
                  : null}
                {isManagementStripeMenu
                  ? getLinkSubscriptionStripe(subscriptionStripeId)
                  : null}
                {isDeleteSignedDocuments &&
                  (data?.agreementContract || data?.cancellationContract) && (
                    <>
                      <Dropdown.Divider />
                    </>
                  )}

                {data.agreementContract && isDeleteSignedDocuments && (
                  <Dropdown.Item
                    onClick={() =>
                      onCancelDocument(constants.DOCUMENT_CONTRACT_DOC_CANCEL)
                    }
                    style={{ color: "red" }}
                  >
                    {addBsArrowRight("Annulla Contratto Firmato")}
                  </Dropdown.Item>
                )}
                {data.cancellationContract && isDeleteSignedDocuments && (
                  <Dropdown.Item
                    onClick={() =>
                      onCancelDocument(constants.DOCUMENT_WITHDRAWAL_DOC_CANCEL)
                    }
                    style={{ color: "red" }}
                  >
                    {addBsArrowRight("Annulla disdetta firmata")}
                  </Dropdown.Item>
                )}
              </DropdownButton>
            </ButtonGroup>
          </div>
        </Col>
      </Row>
      <h5> Cliente Beneficiario</h5>
      <Row>
        <Col>
          <h6> Nome: </h6>
          <span>{data?.customer?.first} </span>
        </Col>
        <Col>
          <h6>Cognome</h6>
          <span>{data?.customer?.last}</span>
        </Col>
        <Col>
          <h6>Mail</h6>
          <span>{data?.customer?.email}</span>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col>
          <h5> Cliente Titolare Pagamenti</h5>
        </Col>
        <Col>
          <h6> {signedContract} </h6>
        </Col>
        <Col>
          <h6> {signedCancelContract}</h6>
        </Col>
      </Row>
      <Row>
        <Col>
          <h6> Nome: </h6>
          <span>{data?.invoicingCustomer?.first} </span>
        </Col>
        <Col>
          <h6>Cognome</h6>
          <span>{data?.invoicingCustomer?.last}</span>
        </Col>
        <Col>
          <h6>Mail</h6>
          <span>{data?.invoicingCustomer?.email}</span>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col>
          <h6> Data Inizio </h6>
          <span>{dateStart} </span>
        </Col>
        <Col>
          <h6>Piano</h6>
          <span>{data?.plan?.name}</span>
        </Col>
        <Col>
          <h6>Metodo di pagamento </h6>
          <span>{paymentType}</span>
        </Col>
      </Row>
      <Row>
        <div className="mt-4" />
      </Row>
      <Row>
        <Col>
          <h6> Numero Zone </h6>
          <span>{data?.zonesQuantity} </span>
        </Col>
        <Col>
          <h6>{labelDataChangeState}</h6>
          <span>{dataChangeState}</span>
        </Col>
        <Col>
          <h6>Sede Corrente </h6>
          <span>{data?.location?.name}</span>
        </Col>
      </Row>
      <Row>
        <div className="mt-4" />
      </Row>
      <Row>
        <Col>
          <h6>Carta attiva</h6>
          <span>{shownCardLast4}</span>
        </Col>
        <Col>
          <h6>CheckIn effettuati in presenza</h6>
          <span> {counterCheckIn - counterCheckInNotPresent}</span>
        </Col>
        <Col>
          <h6>CheckIn Non Presente</h6>
          <span> {counterCheckInNotPresent}</span>
        </Col>
      </Row>
      <Row>
        <div className="mt-4" />
      </Row>
      <Row>
        <Col>
          <h6>Numero Rate Scadute</h6>
          <span>{invoiceCounterExpired}</span>
        </Col>
        <Col></Col>
        <Col></Col>
      </Row>
      <Row>
        <div className="mt-4" />
      </Row>
      <Row>
        <Col>
          <h6>Note</h6>
          <NotesDisplay notes={data?.notes || ""} />
        </Col>
      </Row>
      <Row>
        <div className="mt-4" />
      </Row>
      <Row>
        <Col xs={6} controlid="CheckIn">
          <Row>
            <Col>
              <h6>Ultimi Check-In effettuati</h6>
            </Col>
            <Col>
              <div
                style={{
                  textAlign: "right",
                }}
              >
                {btnPlusCheckIn}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <ListGroup className="list-group-ck">
                {checkinListGroupItems}
              </ListGroup>
            </Col>
          </Row>
        </Col>

        <Col xs={6} controlid="Invoice">
          <Row>
            <Col>
              <h6>{labelInvoices}</h6>
            </Col>
            <Col>
              <div
                style={{
                  textAlign: "right",
                }}
              >
                {btnPlusInvoices}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>{invoicesListGroupItems}</Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default SubscriptionDetailForm;
