import {
  getCheckinDetailUrl,
  getPrepaidPackageCheckinsListUrl,
  getCustomerUrl,
  getPrepaidPackageUpdateUrl,
} from "../api/urls";

import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Col from "react-bootstrap/Col";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import ListGroup from "react-bootstrap/ListGroup";
import NotesDisplay from "./NotesDisplay";
import React from "react";
import Row from "react-bootstrap/Row";
import addBsArrowRight from "./AddBsArrowRight";
import { prepaidPackageTranslationState } from "../api/utils";
import dropDownHeaderView from "./DropDownHeaderView";
import Alert from "react-bootstrap/Alert";

const alertTaxIdNotPresent = (
  <Alert variant="info">
    <p>Il cliente non ha il Codice Fiscale</p>
  </Alert>
);

function PrepaidPackageDetailForm({
  data,
  checkinList,
  counterCheckIn,
  checkInNotPresentCounter,
  currentLocation,
}) {
  const prepaidPackageData = data?.getPrepaidPackage;
  const prepaidPackageUuid = prepaidPackageData?.uuid;
  const isSameLocation =
    currentLocation?.uuid === prepaidPackageData?.location?.uuid ? true : false;

  const dataReceipt = data?.receipt;
  const dataCreditNote = data?.creditNote;
  //
  // load Button for list all Check-Ins
  //
  let btnPlusCheckIn = null;
  if (checkinList?.length !== 0) {
    btnPlusCheckIn = (
      <>
        <div
          style={{
            textAlign: "right",
          }}
        >
          <Button
            variant="outline-info"
            size="sm"
            as="a"
            className="pr-2"
            key={"z8"}
            href={getPrepaidPackageCheckinsListUrl(prepaidPackageUuid)}
          >
            Lista completa Check-In{" "}
            <Badge variant="info">{counterCheckIn}</Badge>
          </Button>
        </div>
      </>
    );
  }
  //
  //load list check-ins
  //
  let checkinListGroupItems = null;
  if (checkinList?.length === 0) {
    checkinListGroupItems = (
      <ListGroup.Item disabled className="list-group-item-ck">
        Non ci sono check-in effettuati
      </ListGroup.Item>
    );
  } else {
    checkinList = checkinList.slice(0, 3);
    checkinListGroupItems = (
      <>
        {checkinList.map((s) => {
          return (
            <ListGroup.Item
              key={s.uuid}
              value={s.index}
              className="list-group-item-ck"
              action
              href={getCheckinDetailUrl(s.uuid)}
            >
              {s.checkInStatus}
              {s.checkInDate.substr(2)} | {s.checkInPlanShortName} |{" "}
              {s.checkInProductName}
            </ListGroup.Item>
          );
        })}
      </>
    );
  }
  const sessionNumber = counterCheckIn - checkInNotPresentCounter;

  let labelInvoiceCustomer = "";
  if (
    prepaidPackageData?.customer?.uuid !==
    prepaidPackageData?.invoicingCustomer?.uuid
  ) {
    labelInvoiceCustomer = (
      <>
        <hr />
        <Row>
          <Col>
            <h5> Cliente Titolare Pagamenti</h5>
          </Col>
        </Row>
        <Row>
          <Col>
            <h6> Nome: </h6>
            <span>{prepaidPackageData?.invoicingCustomer?.first} </span>
          </Col>
          <Col>
            <h6>Cognome</h6>
            <span>{prepaidPackageData?.invoicingCustomer?.last}</span>
          </Col>
          <Col>
            <h6>Mail</h6>
            <span>{prepaidPackageData?.invoicingCustomer?.email}</span>
          </Col>
        </Row>
      </>
    );
  }
  const dropdownCustomer =
    prepaidPackageData?.customer?.uuid ===
    prepaidPackageData?.invoicingCustomer?.uuid ? null : (
      <Dropdown.Item href={getCustomerUrl(prepaidPackageData?.customer?.uuid)}>
        {addBsArrowRight("Beneficiario")}
      </Dropdown.Item>
    );

  function receiptCondition(receiptData, dataCreditNote) {
    let receiptState = null;

    if (!!receiptData) {
      receiptState = (
        <>
          <div className="mt-4" />
          <Row>
            <Col xs={4}>
              <h6> Ricevuta </h6>
              <span> Pagata </span>
            </Col>
            <Col>
              <h6>Data Pagamento</h6>
              <span>{receiptData?.date}</span>
            </Col>
          </Row>
        </>
      );
      if (!!receiptData?.creditNoteId) {
        receiptState = (
          <>
            <div className="mt-4" />
            <Row>
              <Col xs={4}>
                <h6> Ricevuta </h6>
                <span>Rimborsata </span>
              </Col>
              <Col xs={4}>
                <h6>Data Pagamento</h6>
                <span>{receiptData?.date}</span>
              </Col>
              <Col>
                <h6>Data Rimborso</h6>
                <span>{dataCreditNote?.date}</span>
              </Col>
            </Row>
          </>
        );
      }

      if (!!receiptData?.invoice) {
        receiptState = (
          <>
            <div className="mt-4" />
            <Row>
              <Col xs={4}>
                <h6> Ricevuta </h6>
                <span>Annullata </span>
              </Col>
              <Col xs={4}>
                <h6>Data Pagamento</h6>
                <span>{receiptData?.date}</span>
              </Col>
              <Col>
                <h6>Data Fattura</h6>
                <span>{receiptData?.invoice.date}</span>
              </Col>
            </Row>
          </>
        );
      }
      return receiptState;
    }

    return receiptState;
  }

  return (
    <>
      <Row>
        <Col xs={11}>
          <h3 className="text-center"> Pacchetto Prepagato </h3>
        </Col>
        <Col xs={1}>
          <ButtonGroup>
            <DropdownButton
              menualign="right"
              title="Azioni"
              id="dropdown-menu-align-right"
              variant="outline-primary"
            >
              <Dropdown.Item
                href={getPrepaidPackageUpdateUrl(prepaidPackageUuid)}
                disabled={!isSameLocation}
              >
                Modifica Pacchetto Prepagato
              </Dropdown.Item>

              <Dropdown.Divider />
              <Dropdown.Header>{dropDownHeaderView()}</Dropdown.Header>
              {dropdownCustomer}
              <Dropdown.Item
                href={getCustomerUrl(
                  prepaidPackageData?.invoicingCustomer?.uuid
                )}
              >
                {addBsArrowRight(
                  !!labelInvoiceCustomer ? "Titolare" : "Cliente"
                )}
              </Dropdown.Item>

              <Dropdown.Item
                href={getPrepaidPackageCheckinsListUrl(
                  prepaidPackageData?.uuid
                )}
              >
                {addBsArrowRight("Elenco Check-In")}
              </Dropdown.Item>
            </DropdownButton>
          </ButtonGroup>
        </Col>
      </Row>
      <div className="mt-4" />
      <h5>{!!labelInvoiceCustomer ? "Cliente Beneficiario" : "Cliente"}</h5>
      <Row>
        <Col>
          <h6>Nome </h6>
          <span>{prepaidPackageData?.customer?.first} </span>
        </Col>
        <Col>
          <h6>Cognome</h6>
          <span>{prepaidPackageData?.customer?.last}</span>
        </Col>
        <Col>
          <h6>Mail</h6>
          <span>{prepaidPackageData?.customer?.email}</span>
        </Col>
      </Row>
      {labelInvoiceCustomer}
      <hr />
      <Row>
        <Col xs={4}>
          <h6>Data Inizio </h6>
          <span>{prepaidPackageData?.startDate} </span>
        </Col>
        <Col xs={4}>
          <h6>Data Scadenza </h6>
          <span>{prepaidPackageData?.endDate} </span>
        </Col>
      </Row>
      <div className="mt-4" />
      <Row>
        <Col xs={4}>
          <h6> Numero Sessioni</h6>
          <span>{prepaidPackageData?.sessionQuantity} </span>
        </Col>
        <Col xs={4}>
          <h6>Stato</h6>
          <span>
            {prepaidPackageTranslationState[prepaidPackageData?.state]}
          </span>
        </Col>
        <Col xs={4}>
          <h6>Sede Corrente</h6>
          <span>{prepaidPackageData?.location?.name}</span>
        </Col>
      </Row>
      <div className="mt-4" />
      <Row>
        <Col xs={4}>
          <h6>Piano</h6>
          <span>{prepaidPackageData?.packagePlan?.name}</span>
        </Col>
        <Col xs={4}>
          <h6>Costo</h6>
          <span>{prepaidPackageData?.packagePlan?.price}</span>
        </Col>
        <Col xs={4}>
          <h6>Trattamento</h6>
          <span>{prepaidPackageData?.packagePlan?.product?.name}</span>
        </Col>
      </Row>
      <div className="mt-4" />
      <Row>
        <Col>
          <h6>Check-In effettuati in presenza</h6>
          <span> {sessionNumber}</span>
        </Col>
        <Col>
          <h6>Check-In Non Presente</h6>
          <span> {checkInNotPresentCounter}</span>
        </Col>
        <Col></Col>
      </Row>
      <div className="mt-4" />
      <hr />
      {receiptCondition(dataReceipt, dataCreditNote)}
      <Row>
        <div className="mt-4" />
      </Row>
      <Row>
        <Col>
          <h6>Note</h6>
          <NotesDisplay notes={prepaidPackageData?.notes} />
        </Col>
      </Row>
      <div className="mt-4" />
      <Row>
        <Col xs={6}>
          <Row>
            <Col>
              <h6>Ultimi Check-In effettuati</h6>
            </Col>
            <Col>{btnPlusCheckIn}</Col>
          </Row>
          <Row>
            <Col>
              <ListGroup className="list-group-ck">
                {checkinListGroupItems}
              </ListGroup>
            </Col>
          </Row>
        </Col>
      </Row>
      <div className="mt-4" />
      <Row>
        {!prepaidPackageData?.invoicingCustomer?.taxId
          ? alertTaxIdNotPresent
          : null}
      </Row>
    </>
  );
}

export default PrepaidPackageDetailForm;
