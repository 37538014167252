import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";
import MainTable from "./MainTable";
import { getSalesLedgerReportUrl } from "../api/urls";
import {
  getItalianMonthDescription,
  firstDayNextMonth,
  getLastDayOfMonth,
  formatDate,
  firstDayPreviousMonth,
} from "../api/date-utils";
import { utils, writeFile } from 'xlsx';

function calculateAmounts(amount, vatRate) {
  const vatAmount = +((Number(amount) * Number(vatRate)) / 100).toFixed(2);
  const netAmount = +(Number(amount) - Number(vatAmount)).toFixed(2);
  return { vatAmount, netAmount };
}

function sumAmounts(gross, net, vat) {
  const amountSum = (+Number(gross) - (+Number(net) + +Number(vat))).toFixed(2);
  return amountSum;
}

const SalesLedgerTotalMonthsList = ({ columns, data, dailyReportTotal }) => {
  // Destructuring of variables to avoid repeating dailyReportTotal
  const {
    year,
    month,
    grossAmount,
    dailyTotalAmountReceipts,
    dailyTotalAmountRefunds,
    dailyTotalAmountInvoices,
    netAmount,
    vatRate,
    day,
    vat,
  } = dailyReportTotal;

  const lastDateOfMonth = getLastDayOfMonth(new Date(year, month - 1, 1));
  const firstDayMonth = `1-${month}`;
  const monthDescription = getItalianMonthDescription(month);

  const { vatAmount: vatGrossAmount, netAmount: netGrossAmount } =
    calculateAmounts(grossAmount, vatRate);

  const {
    vatAmount: vatGrossAmountReceipts,
    netAmount: netGrossAmountReceipts,
  } = calculateAmounts(dailyTotalAmountReceipts, vatRate);

  const { vatAmount: vatGrossAmountRefunds, netAmount: netGrossAmountRefunds } =
    calculateAmounts(dailyTotalAmountRefunds, vatRate);

  const {
    vatAmount: vatGrossAmountInvoices,
    netAmount: netGrossAmountInvoices,
  } = calculateAmounts(dailyTotalAmountInvoices, vatRate);

  // Orizzontal check
  const checkRowGrossAmount = +(
    grossAmount -
    (dailyTotalAmountReceipts -
      dailyTotalAmountRefunds -
      dailyTotalAmountInvoices)
  ).toFixed(2);

  const checkRowNetAmount = +(
    netGrossAmount -
    (netGrossAmountReceipts - netGrossAmountRefunds - netGrossAmountInvoices)
  ).toFixed(2);
  const checkRowVatAmount = +(
    vatGrossAmount -
    (vatGrossAmountReceipts - vatGrossAmountRefunds - vatGrossAmountInvoices)
  ).toFixed(2);

  const enabledCheckRow = [
    checkRowGrossAmount,
    checkRowNetAmount,
    checkRowVatAmount,
  ].some((value) => Math.abs(value) >= 0.1);

  // Vertical check
  const ckColGrossAmount = sumAmounts(grossAmount, netAmount, vat);

  const ckColNetAmount = sumAmounts(
    dailyTotalAmountReceipts,
    netGrossAmountReceipts,
    vatGrossAmountReceipts
  );

  const ckColNetRefunds = sumAmounts(
    dailyTotalAmountRefunds,
    netGrossAmountRefunds,
    vatGrossAmountRefunds
  );

  const ckColNetInvoices = sumAmounts(
    dailyTotalAmountInvoices,
    netGrossAmountInvoices,
    vatGrossAmountInvoices
  );

  const enabledCheckCol = [
    ckColGrossAmount,
    ckColNetAmount,
    ckColNetRefunds,
    ckColNetInvoices,
  ].some((value) => Math.abs(value) >= 0.1);

  const renderCheckCell = (enabled, value) =>
    enabled ? <td>{value}</td> : <td className="d-none"></td>;

  const nextMonth = firstDayNextMonth(formatDate(`${year}-${month}-${day}`));
  const previousMonth = firstDayPreviousMonth(
    formatDate(`${year}-${month}-${day}`)
  );

  const nextPage = nextMonth && (
    <Button size="sm" variant="Link" href={getSalesLedgerReportUrl(nextMonth)}>
      Mese Successivo {">"}
    </Button>
  );

  const previousPage = (
    <Button
      size="sm"
      variant="Link"
      href={getSalesLedgerReportUrl(previousMonth)}
    >
      {"<"} Mese Precedente
    </Button>
  );

  const exportToExcel = () => {
    // Prepare data for monthly totals table
    const totalsData = [
      ['', 'Corrispettivi (22%)', 'E-Commerce Ricevute (22%)', 'E-Commerce Resi (22%)', 'E-Commerce Fatture (22%)', enabledCheckRow ? 'Check' : ''],
      ['LORDO', 
       Number(grossAmount), 
       Number(dailyTotalAmountReceipts), 
       -Number(dailyTotalAmountRefunds), 
       -Number(dailyTotalAmountInvoices),
       enabledCheckRow ? Number(checkRowGrossAmount) : ''
      ],
      ['NETTO',
       Number(netGrossAmount),
       Number(netGrossAmountReceipts),
       -Number(netGrossAmountRefunds),
       -Number(netGrossAmountInvoices),
       enabledCheckRow ? Number(checkRowNetAmount) : ''
      ],
      ['IVA',
       Number(vatGrossAmount),
       Number(vatGrossAmountReceipts),
       -Number(vatGrossAmountRefunds),
       -Number(vatGrossAmountInvoices),
       enabledCheckRow ? Number(checkRowVatAmount) : ''
      ],
      // Add vertical check row if enabled
      enabledCheckCol ? [
        'Check',
        Number(ckColGrossAmount),
        Number(ckColNetAmount),
        Number(ckColNetRefunds),
        Number(ckColNetInvoices),
        ''
      ] : []
    ].filter(row => row.length > 0); // Remove empty rows

    // Prepare data for daily table
    const dailyData = [
      // Column headers
      [
        'Giorno',
        'Corrispettivi',
        'Netto',
        'IVA',
        'E-Commerce Ricevute',
        'E-Commerce Resi',
        'E-Commerce Fatture'
      ],
      // VAT rate subtitles
      [
        '',
        'Aliquota 22%',
        'Aliquota 22%',
        'Aliquota 22%',
        'Aliquota 22%',
        'Aliquota 22%',
        'Aliquota 22%'
      ],
      // Row data
      ...data.map(row => [
        row.day,
        Number(row.grossAmount),
        Number(row.netAmount),
        Number(row.vat),
        Number(row.dailyTotalAmountReceipts),
        Number(row.dailyTotalAmountRefunds),
        Number(row.dailyTotalAmountInvoices)
      ])
    ];

    // Create new workbook
    const wb = utils.book_new();

    // Create and add totals sheet
    const wsTotals = utils.aoa_to_sheet(totalsData);
    utils.book_append_sheet(
      wb, 
      wsTotals, 
      `Totali ${monthDescription} ${year}`
    );

    // Create and add daily data sheet
    const wsDaily = utils.aoa_to_sheet(dailyData);
    utils.book_append_sheet(
      wb, 
      wsDaily, 
      `Giornalieri ${monthDescription} ${year}`
    );

    // Set column widths for both sheets
    const wscolsTotals = [
      {wch: 10},  // Tipo
      {wch: 20},  // Corrispettivi
      {wch: 25},  // E-Commerce Ricevute
      {wch: 25},  // E-Commerce Resi
      {wch: 25},  // E-Commerce Fatture
      {wch: 0},  // Check
    ];
    const wscolsDayly = [
      {wch: 10},  // Giorno
      {wch: 20},  // Corrispettivi
      {wch: 20},  // Netto
      {wch: 20},  // IVA
      {wch: 20},  // E-Commerce Ricevute
      {wch: 20},  // E-Commerce Resi
      {wch: 20},  // E-Commerce Fatture
      {wch: 20},  // Check
    ];
    wsTotals['!cols'] = wscolsTotals;
    wsDaily['!cols'] = wscolsDayly;

    // Add styles for negative numbers in red
    const wsRange = utils.decode_range(wsTotals['!ref']);
    for(let R = 1; R <= wsRange.e.r; ++R) {
      for(let C = 1; C <= wsRange.e.c; ++C) {
        const cell = wsTotals[utils.encode_cell({r: R, c: C})];
        if(cell && cell.v < 0) {
          if(!cell.s) cell.s = {};
          cell.s.color = { rgb: "FF0000" }; // Red
        }
      }
    }

    // Download file
    writeFile(wb, `registro_corrispettivi_${year}_${month}.xlsx`);
  };

  const exportToCSV = () => {
    // Prepare data for monthly totals table
    const totalsData = [
      ['', 'Corrispettivi (22%)', 'E-Commerce Ricevute (22%)', 'E-Commerce Resi (22%)', 'E-Commerce Fatture (22%)', enabledCheckRow ? 'Check' : ''],
      ['LORDO', 
       Number(grossAmount), 
       Number(dailyTotalAmountReceipts), 
       -Number(dailyTotalAmountRefunds), 
       -Number(dailyTotalAmountInvoices),
      ],
      ['NETTO',
       Number(netGrossAmount),
       Number(netGrossAmountReceipts),
       -Number(netGrossAmountRefunds),
       -Number(netGrossAmountInvoices),
      ],
      ['IVA',
       Number(vatGrossAmount),
       Number(vatGrossAmountReceipts),
       -Number(vatGrossAmountRefunds),
       -Number(vatGrossAmountInvoices),
      ],
    ].filter(row => row.length > 0);

    // Prepare data for daily table
    const dailyData = [
      [
        'Giorno',
        'Corrispettivi',
        'Netto',
        'IVA',
        'E-Commerce Ricevute',
        'E-Commerce Resi',
        'E-Commerce Fatture'
      ],
      [
        '',
        'Aliquota 22%',
        'Aliquota 22%',
        'Aliquota 22%',
        'Aliquota 22%',
        'Aliquota 22%',
        'Aliquota 22%'
      ],
      ...data.map(row => [
        row.day,
        Number(row.grossAmount),
        Number(row.netAmount),
        Number(row.vat),
        Number(row.dailyTotalAmountReceipts),
        Number(row.dailyTotalAmountRefunds),
        Number(row.dailyTotalAmountInvoices)
      ])
    ];

    // Convert data to CSV
    const totalsCSV = totalsData
      .map(row => row.join(';'))
      .join('\n');

    const dailyCSV = dailyData
      .map(row => row.join(';'))
      .join('\n');

    // Create and download CSV files
    const downloadCSV = (content, filename) => {
      const blob = new Blob([content], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    };

    downloadCSV(totalsCSV, `totali_${monthDescription}_${year}.csv`);
    downloadCSV(dailyCSV, `giornalieri_${monthDescription}_${year}.csv`);
  };

  return (
    <>
      <Row>
        <Col xs={2}>
          <div style={{ textAlign: "left" }}>{previousPage}</div>
        </Col>
        <Col xs={8}>
          <h2 className="text-center">Registro dei Corrispettivi</h2>
          <h4 className="text-center">
            Anno: <strong>{year}</strong> Mese:{" "}
            <strong>{monthDescription}</strong>
          </h4>
        </Col>
        <Col xs={2}>
          <div style={{ textAlign: "right" }}>
            {nextPage}
            <div className="d-flex justify-content-end align-items-center mt-2">
              <Button 
                size="sm" 
                variant="success" 
                onClick={exportToExcel}
                className="me-2"
              >
                Esporta Excel
              </Button>
              <Button 
                size="sm" 
                variant="success" 
                onClick={exportToCSV}
              >
                Esporta CSV
              </Button>
            </div>
          </div>
        </Col>
      </Row>
      <div className="mt-4" />
      <Row>{MainTable(columns, data)}</Row>

      <h5>
        Totali del Periodo: {firstDayMonth} / {lastDateOfMonth}
      </h5>
      <Table bordered hover responsive>
        <thead>
          <tr>
            <th></th>
            <th
              style={{
                textAlign: "center",
              }}
            >
              Corrispettivi
              <br />
              <small>Aliquota 22 %</small>
            </th>
            <th
              style={{
                textAlign: "center",
              }}
            >
              E-Commerce Ricevute
              <br />
              <small>Aliquota 22 %</small>
            </th>
            <th
              style={{
                textAlign: "center",
              }}
            >
              E-Commerce Resi
              <br />
              <small>Aliquota 22 %</small>
            </th>
            <th
              style={{
                textAlign: "center",
              }}
            >
              E-Commerce Fatture
              <br />
              <small>Aliquota 22 %</small>
            </th>
            {enabledCheckRow && <th>Check</th>}
          </tr>
        </thead>
        <tbody>
          <tr className="table-active">
            <td>LORDO</td>
            <td>
              <div style={{ textAlign: "right", fontWeight: "bold" }}>
                {(+grossAmount).toLocaleString("it-IT", {
                  minimumFractionDigits: 2,
                })}
              </div>
            </td>
            <td>
              <div style={{ textAlign: "right" }}>
                {(+dailyTotalAmountReceipts).toLocaleString("it-IT", {
                  minimumFractionDigits: 2,
                })}
              </div>
            </td>
            <td className="text-danger">
              <div style={{ textAlign: "right" }}>
                -
                {(+dailyTotalAmountRefunds).toLocaleString("it-IT", {
                  minimumFractionDigits: 2,
                })}
              </div>
            </td>
            <td className="text-danger">
              <div style={{ textAlign: "right" }}>
                -
                {(+dailyTotalAmountInvoices).toLocaleString("it-IT", {
                  minimumFractionDigits: 2,
                })}
              </div>
            </td>
            {renderCheckCell(enabledCheckRow, checkRowGrossAmount)}
          </tr>
          <tr>
            <td>NETTO</td>
            <td>
              <div style={{ textAlign: "right", fontWeight: "bold" }}>
                {(+netGrossAmount).toLocaleString("it-IT", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </div>
            </td>
            <td>
              <div style={{ textAlign: "right" }}>
                {(+netGrossAmountReceipts).toLocaleString("it-IT", {
                  minimumFractionDigits: 2,
                })}
              </div>
            </td>
            <td className="text-danger">
              <div style={{ textAlign: "right" }}>
                -
                {(+netGrossAmountRefunds).toLocaleString("it-IT", {
                  minimumFractionDigits: 2,
                })}
              </div>
            </td>
            <td className="text-danger">
              <div style={{ textAlign: "right" }}>
                -
                {(+netGrossAmountInvoices).toLocaleString("it-IT", {
                  minimumFractionDigits: 2,
                })}
              </div>
            </td>
            {renderCheckCell(enabledCheckRow, checkRowNetAmount)}
          </tr>

          <tr>
            <td>IVA</td>
            <td>
              <div style={{ textAlign: "right", fontWeight: "bold" }}>
                {(+vatGrossAmount).toLocaleString("it-IT", {
                  minimumFractionDigits: 2,
                })}
              </div>
            </td>
            <td>
              <div style={{ textAlign: "right" }}>
                {(+vatGrossAmountReceipts).toLocaleString("it-IT", {
                  minimumFractionDigits: 2,
                })}
              </div>
            </td>
            <td className="text-danger">
              <div style={{ textAlign: "right" }}>
                -
                {(+vatGrossAmountRefunds).toLocaleString("it-IT", {
                  minimumFractionDigits: 2,
                })}
              </div>
            </td>
            <td className="text-danger">
              <div style={{ textAlign: "right" }}>
                -
                {(+vatGrossAmountInvoices).toLocaleString("it-IT", {
                  minimumFractionDigits: 2,
                })}
              </div>
            </td>
            {renderCheckCell(enabledCheckRow, checkRowVatAmount)}
          </tr>
          <tr>
            {renderCheckCell(enabledCheckCol, "Check")}
            {renderCheckCell(enabledCheckCol, ckColGrossAmount)}
            {renderCheckCell(enabledCheckCol, ckColNetAmount)}
            {renderCheckCell(enabledCheckCol, ckColNetRefunds)}
            {renderCheckCell(enabledCheckCol, ckColNetInvoices)}
            {renderCheckCell(enabledCheckCol, "")}
          </tr>
        </tbody>
      </Table>
    </>
  );
};

export default SalesLedgerTotalMonthsList;
