import { gql } from "@apollo/client";

export const GET_STRIPE_PAYMENT_METHODS = gql`
  query GetStripePaymentMethods {
    stripePaymentMethodList @client
  }
`;

export const QUERY_ALL_PLANS = gql`
  query allPlans {
    allPlans {
      uuid
      name
    }
  }
`;

export const QUERY_ALL_CUSTOMER = gql`
  query allCustomer {
    allCustomer {
      uuid
      first
      last
      fullName
      email
    }
    referralSources {
      uuid
      name
    }
  }
`;
export const QUERY_CUSTOMER = gql`
  query customer(
    $customerUuid: UUID
    $checkInMaxLimit: Int
    $listType: CreditListType = ALL
  ) {
    customer(customerUuid: $customerUuid) {
      uuid
      first
      last
      fullName
      taxId
      email
      phoneNumber
      fcId
      stripeId
      notes
      needsUpdate
      agreementPrivacyConsent {
        uuid
        signedDocumentUrl
      }
      agreementLaserTreatment {
        uuid
        signedDocumentUrl
      }
      referralSource {
        uuid
      }
      address {
        road
        city
        county
        postcode
        houseNumber
        country
      }
      tags {
        id
        name
      }
      location {
        uuid
        name
      }
    }
    customerSubscriptions(customerUuid: $customerUuid) {
      uuid
      plan {
        uuid
        shortName
        name
      }
      paymentType
      startDate
      state
      stripeSubscriptionId
      location {
        uuid
        name
      }
      paidState
      customer {
        uuid
      }
      invoicingCustomer {
        uuid
        fullName
      }
      agreementContract {
        uuid
      }
    }
    checkInCounter: customerAllCheckinsCount(customerUuid: $customerUuid)
    checkInCustomer: customerAllCheckins(
      customerUuid: $customerUuid
      checkInMaxLimit: $checkInMaxLimit
    ) {
      uuid
      date
      isPresent
      checkInStatus
      subscription {
        uuid
        plan {
          uuid
          shortName
          name
        }
      }
      product {
        uuid
        name
        enabled
        hasZones
      }
      checkinFundingSource {
        uuid
        name
        enabled
      }
    }
    referralSources {
      uuid
      name
    }
    customerSubscriptionsInvoicing(customerUuid: $customerUuid) {
      uuid
      startDate
      paymentType
      state
      stripeSubscriptionId
      customer {
        uuid
        fullName
      }
      plan {
        uuid
        shortName
        name
      }
      paidState
      agreementContract {
        uuid
      }
    }
    customerCreditList(customerUuid: $customerUuid, listType: $listType) {
      credit {
        uuid
        startDate
        endDate
        sessionQuantity
        sponsor
        notes
        state
        zonesQuantity
        plan {
          uuid
          name
          shortName
        }
        creditType {
          uuid
          name
        }
      }
      checkinCount
    }
    userSetting: getUserSetting {
      uuid
      currentLocation {
        uuid
        name
      }
      user {
        id
        username
        groups {
          id
          name
        }
      }
    }
    getAgreementPrivacy {
      uuid
      name
      signingDocumentUrl
    }
    getAgreementLaser {
      uuid
      name
      signingDocumentUrl
    }
    getAgreementLaserMinor {
      uuid
      name
      signingDocumentUrl
    }
    customerPrepaidPackageList(
      customerUuid: $customerUuid
      listType: $listType
    ) {
      PrepaidPackage {
        uuid
        startDate
        endDate
        sessionQuantity
        notes
        state
        customer {
          uuid
        }
        invoicingCustomer {
          uuid
          fullName
        }
        packagePlan {
          uuid
          description
          shortName
        }
        lastCheckin {
          date
        }
      }
      checkinCount
    }
    customerPrepaidPackageInvoicing(customerUuid: $customerUuid) {
      uuid
      startDate
      state
      customer {
        uuid
        fullName
      }
      packagePlan {
        uuid
        shortName
        description
      }
    }
  }
`;

export const QUERY_CHECKIN_CUSTOMER = gql`
  query checkinCustomer(
    $customerUuid: UUID
    $checkInMaxLimit: Int
    $foundingsourceUuid: UUID
    $foundingsourceEnabled: Boolean
  ) {
    customer(customerUuid: $customerUuid) {
      uuid
      first
      last
      taxId
      email
      phoneNumber
      taxIdForeignerNotVerified
      agreementPrivacyConsent {
        uuid
      }
      agreementLaserTreatment {
        uuid
      }
      address {
        country
      }
    }
    featureFlags: getFeatureFlags {
      name
      enabled
    }
    workersList {
      uuid
      first
      last
    }
    stationsList {
      uuid
      name
    }
    customerSubscriptionsEnabled(customerUuid: $customerUuid) {
      subscription {
        uuid
        zonesQuantity
        startDate
        state
        paidState
        quantity
        lastCheckin {
          date
        }
        plan {
          uuid
          name
          checkinCadence
          maxCheckinsAllowed
          category
        }
        agreementContract {
          uuid
        }
      }
      checkinPossible
      invoices {
        uuid
      }
    }
    allCheckinFundingSource {
      uuid
      name
    }
    checkInCounter: customerAllCheckinsCount(customerUuid: $customerUuid)
    checkInCustomer: customerAllCheckins(
      customerUuid: $customerUuid
      checkInMaxLimit: $checkInMaxLimit
    ) {
      uuid
      date
      isPresent
      checkInStatus
      location {
        uuid
        name
      }
      subscription {
        uuid
        plan {
          uuid
          shortName
        }
      }
      product {
        uuid
        name
      }
      checkinFundingSource {
        uuid
        name
      }
      credit {
        uuid
        plan {
          uuid
          shortName
        }
      }
    }
    customerPlanProducts: plansForCustomer(customerUuid: $customerUuid) {
      plan {
        uuid
        name
      }
      product {
        uuid
        name
        hasZones
        consentLaserIsRequired
      }
    }
    productsForFundingSource(
      foundingsourceUuid: $foundingsourceUuid
      foundingsourceEnabled: $foundingsourceEnabled
    ) {
      checkinfundingsource {
        uuid
      }
      product {
        uuid
        name
        hasZones
        consentLaserIsRequired
      }
    }
    customerCreditList(customerUuid: $customerUuid) {
      credit {
        uuid
        startDate
        endDate
        sessionQuantity
        sponsor
        notes
        state
        zonesQuantity
        lastCheckin {
          date
        }
        plan {
          uuid
          name
          checkinCadence
        }
        creditType {
          uuid
          name
        }
      }
      checkinCount
    }
    customerPrepaidPackageList(customerUuid: $customerUuid) {
      PrepaidPackage {
        uuid
        startDate
        endDate
        sessionQuantity
        notes
        state
        packagePlan {
          uuid
          description
          name
          checkinCadence
          product {
            uuid
            name
            hasZones
            consentLaserIsRequired
          }
        }
        lastCheckin {
          date
        }
      }
      checkinCount
    }
    listProductsPriceForCheckin {
      product {
        uuid
        name
      }
      price
    }
    openCheckins {
      uuid
      customer {
        uuid
      }
      worker {
        uuid
      }
      station {
        uuid
      }
    }
  }
`;

export const QUERY_LOAD_CHECKIN_UPDATE = gql`
  query CheckInUpdate(
    $checkinUuid: UUID
    $foundingsourceUuid: UUID
    $foundingsourceEnabled: Boolean
    $listType: CreditListType
  ) {
    checkin(checkinUuid: $checkinUuid) {
      uuid
      date
      time
      productZones
      notes
      isPresent
      checkInStatus
      location {
        uuid
        name
      }
      customer {
        uuid
        first
        last
        taxId
        email
        phoneNumber
      }
      subscription {
        uuid
        startDate
        state
        plan {
          uuid
          name
        }
      }
      product {
        uuid
        name
        enabled
        hasZones
      }
      worker {
        uuid
        first
        last
      }
      station {
        uuid
        name
        enabled
      }
      checkinFundingSource {
        uuid
        name
        enabled
      }
      credit {
        uuid
        plan {
          uuid
          shortName
        }
      }
      prepaidPackage {
        uuid
        packagePlan {
          uuid
          shortName
          name
        }
      }
    }
    getInvoiceForCheckin(checkinUuid: $checkinUuid) {
      uuid
      sdiSentDate
      creditNoteId
      progressiveNumber
      date
      receiptDestinationEmail
    }
    workersList {
      uuid
      first
      last
    }
    stationsList {
      uuid
      name
    }
    checkinCustomerSubscriptions(checkinUuid: $checkinUuid) {
      subscription {
        uuid
        zonesQuantity
        startDate
        state
        paidState
        quantity
        lastCheckin {
          date
        }
        plan {
          uuid
          name
          checkinCadence
          maxCheckinsAllowed
          category
        }
      }
      checkinPossible
      invoices {
        uuid
      }
    }
    allCheckinFundingSource {
      uuid
      name
      enabled
    }
    customerPlanProducts: plansForCustomer(checkinUuid: $checkinUuid) {
      plan {
        uuid
        name
      }
      product {
        uuid
        name
        hasZones
      }
    }
    productsForFundingSource(
      foundingsourceUuid: $foundingsourceUuid
      foundingsourceEnabled: $foundingsourceEnabled
    ) {
      checkinfundingsource {
        uuid
      }
      product {
        uuid
        name
        hasZones
      }
    }
    customerCreditList(checkinUuid: $checkinUuid, listType: $listType) {
      credit {
        uuid
        startDate
        endDate
        sessionQuantity
        sponsor
        notes
        state
        zonesQuantity
        plan {
          uuid
          name
          checkinCadence
        }
        creditType {
          uuid
          name
        }
      }
      checkinCount
    }
    customerPrepaidPackageList(checkinUuid: $checkinUuid, listType: $listType) {
      PrepaidPackage {
        uuid
        startDate
        endDate
        sessionQuantity
        notes
        state
        packagePlan {
          uuid
          description
          name
          checkinCadence
          product {
            uuid
            name
            hasZones
            consentLaserIsRequired
          }
        }
      }
      checkinCount
    }
    userSetting: getUserSetting {
      uuid
      currentLocation {
        uuid
        name
      }
    }
    listProductsPriceForCheckin(checkinUuid: $checkinUuid) {
      product {
        uuid
        name
      }
      price
    }
    checkinWorkerIsEnabled(checkinUuid: $checkinUuid)
  }
`;

export const QUERY_CUSTOMER_ALL_CHECKINS = gql`
  query customerAllCheckins($customerUuid: UUID) {
    checkInCustomer: customerAllCheckins(customerUuid: $customerUuid) {
      uuid
      date
      isPresent
      checkInStatus
      closedDate
      timestamp
      subscription {
        uuid
        startDate
        state
        paymentType
        plan {
          uuid
          name
        }
      }
      product {
        uuid
        name
      }
      worker {
        first
        last
      }
      time
      station {
        name
      }
      productZones
      notes
      checkinFundingSource {
        uuid
        name
      }
      location {
        uuid
        name
      }
      credit {
        uuid
        startDate
        plan {
          uuid
          name
        }
      }
    }
    customer(customerUuid: $customerUuid) {
      uuid
      first
      last
      taxId
      email
    }
    userSetting: getUserSetting {
      uuid
      currentLocation {
        uuid
        name
      }
    }
  }
`;

export const QUERY_CUSTOMER_SEARCH = gql`
  query customerSearch($searchQuery: String) {
    customerSearch(searchQuery: $searchQuery) {
      uuid
      first
      last
      phoneNumber
      email
      taxId
    }
  }
`;

export const QUERY_ALL_REFERRAL_SOURCES = gql`
  query allReferralSources {
    referralSources {
      uuid
      name
    }
  }
`;
export const QUERY_GET_SUBSCRIPTION = gql`
  query getSubscription($subscriptionUuid: UUID, $checkInMaxLimit: Int) {
    subscription(subscriptionUuid: $subscriptionUuid) {
      uuid
      startDate
      notes
      zonesQuantity
      state
      paymentType
      timestamp
      cancelDate
      scheduledDate
      stripeSubscriptionId
      paidState
      automaticDeletionForUnpaid
      agreementContract {
        uuid
        signedDocumentUrl
      }
      cancellationContract {
        uuid
        signedDocumentUrl
      }
      plan {
        uuid
        name
        price
        contractUrl
        agreementType {
          uuid
          name
          signingDocumentUrl
        }
        cancelAgreementType {
          uuid
          name
          signingDocumentUrl
        }
      }
      invoicingCustomer {
        uuid
        last
        first
        email
        taxId
        stripeId
        fcId
      }
      customer {
        uuid
        last
        first
        email
        needsUpdate
        stripeId
        fcId
      }
      location {
        uuid
        name
      }
    }
    checkInCounter: subscriptionCheckinCount(
      subscriptionUuid: $subscriptionUuid
    )
    checkInCounterNotPresent: subscriptionCheckinCountNotPresent(
      subscriptionUuid: $subscriptionUuid
    )
    invoiceCounterExpired: getExpiredSubscriptionsInvoiceCount(
      subscriptionUuid: $subscriptionUuid
    )
    subscriptionCheckins(
      subscriptionUuid: $subscriptionUuid
      checkInMaxLimit: $checkInMaxLimit
    ) {
      uuid
      date
      isPresent
      checkInStatus
      subscription {
        uuid
        plan {
          uuid
          name
          shortName
        }
      }
      product {
        uuid
        name
      }
      checkinFundingSource {
        uuid
        name
      }
    }
    userSetting: getUserSetting {
      uuid
      currentLocation {
        uuid
        name
      }
      user {
        id
        username
        groups {
          id
          name
        }
      }
    }
  }
`;
export const QUERY_INVOICE_FOR_SUBSCRIPTION = gql`
  query invoiceResult($subscriptionUuid: UUID) {
    invoiceForSubscription(subscriptionUuid: $subscriptionUuid) {
      result {
        __typename
        ... on InvoiceResult {
          dateStart
          dateEnd
          amountPaid
          statusDetail
          status
        }
      }
      error {
        message
        code
      }
    }
  }
`;

export const QUERY_STRIPE_PAYMENT_METHODS_FOR_CUSTOMER = gql`
  query stripePaymentMethodsForCustomer($customerUuid: UUID) {
    stripePaymentMethodsForCustomer(customerUuid: $customerUuid) {
      id
      paymentType
      paymentVisibleIdentifier
      created
    }
  }
`;

export const QUERY_SUBSCRIPTION_AFTER_CREATION = gql`
  query getSubscriptionAfterCreation($subscriptionUuid: UUID) {
    subscription(subscriptionUuid: $subscriptionUuid) {
      uuid
      invoicingCustomer {
        uuid
        first
        last
        email
        taxId
      }
      plan {
        uuid
        contractUrl
        price
        agreementType {
          uuid
          signingDocumentUrl
        }
      }
      location {
        uuid
        name
      }
      paymentType
    }
  }
`;

export const QUERY_GET_CHECKIN_PAGINATOR = gql`
  query getCheckinPaginator($page: Int, $pageSize: Int) {
    checkinPaginator(page: $page, pageSize: $pageSize) {
      page
      totPages
      pageSize
      hasNext
      hasPrev
      objects {
        uuid
        date
        time
        isPresent
        checkInStatus
        closedDate
        timestamp
        customer {
          uuid
          fullName
        }
        subscription {
          uuid
          plan {
            uuid
            name
          }
        }
        product {
          uuid
          name
        }
        checkinFundingSource {
          uuid
          name
        }
        credit {
          uuid
          plan {
            uuid
            name
          }
        }
      }
    }
    paginator: checkinPaginator(page: $page, pageSize: $pageSize) {
      page
      totPages
      pageSize
      hasNext
      hasPrev
    }
  }
`;
export const QUERY_UNPAIDSUBSCRIPTIONS = gql`
  query invoiceResultPastDue {
    unpaidSuscriptions {
      result {
        __typename
        ... on UnpaidSubscription {
          subscription {
            uuid
            invoicingCustomer {
              uuid
              email
              stripeId
              fullName
            }
            plan {
              uuid
              name
            }
          }
          invoiceDateEnd
          attemptedAmount
          reason
          stripeErrorCode
          paymentIntentStatus
        }
      }
      error {
        message
        code
      }
    }
  }
`;

export const QUERY_LIST_SUBSCRIPTION_PAGINATOR = gql`
  query getSubscriptionPaginator(
    $page: Int
    $pageSize: Int
    $parameter: String
  ) {
    subscriptionPaginator(
      page: $page
      pageSize: $pageSize
      parameter: $parameter
    ) {
      page
      totPages
      pageSize
      hasNext
      hasPrev
      listSubscription: objects {
        checkinCount
        lastCheckin
        subscription {
          uuid
          paymentType
          startDate
          state
          scheduledDate
          lastCheckin {
            date
          }
          customer {
            uuid
            fullName
            needsUpdate
          }
          invoicingCustomer {
            uuid
            fullName
          }
          plan {
            name
            uuid
          }
        }
      }
    }
    paginator: subscriptionPaginator(
      page: $page
      pageSize: $pageSize
      parameter: $parameter
    ) {
      page
      totPages
      pageSize
      hasNext
      hasPrev
    }
    allPlans {
      uuid
      name
    }
  }
`;

export const QUERY_GET_SUBSCRIPTION_PAYMENT = gql`
  query getSubscriptionPayment(
    $subscriptionUuid: UUID
    $limitNumberInvoices: Int
  ) {
    invoiceForSubscription(
      subscriptionUuid: $subscriptionUuid
      limitNumberInvoices: $limitNumberInvoices
    ) {
      result {
        ... on InvoiceResult {
          dateStart
          dateEnd
          amountPaid
          statusDetail
          status
          paidAt
          refundedAmount
          refundedDate
          errorMessage
          checkinCount
          checkinCountNoPresent
          stripeInvoiceId
          nextPaymentAttempt
          invoiceUuid
          invoiceExpired
          invoiceCheckin {
            uuid
            date
          }
        }
      }
      error {
        message
        code
      }
    }
    stripeSubscription(subscriptionUuid: $subscriptionUuid) {
      result {
        ... on StripeSubscription {
          id
          status
          planInterval
          planIntervalCount
          planNickname
          pauseBehavior
          pauseResumesAt
          paymentMethod {
            id
            paymentType
            paymentVisibleIdentifier
          }
        }
      }
      error {
        message
        code
      }
    }
  }
`;
export const QUERY_LIST_PLANS = gql`
  query ProductsListPlan($disabled: Boolean) {
    plansList(disabled: $disabled) {
      uuid
      name
      disabled
      shortName
      subscriptionCount
      subscriptionCountActive
      subscriptionCountPaused
    }
  }
`;

export const QUERY_PLAN = gql`
  query ProductsForPlan($planUuid: UUID) {
    plan(planUuid: $planUuid) {
      uuid
      cadence
      price
      name
      status: disabled
      contractUrl
      minPaymentsRequired
      checkinCadence
      maxCheckinsAllowed
      notes
      shortName
      agreementType {
        uuid
        signingDocumentUrl
      }
      cancelAgreementType {
        uuid
        signingDocumentUrl
      }
    }
    productsForPlan(planUuid: $planUuid) {
      product {
        uuid
        name
      }
    }
  }
`;

export const CUSTOMER_PLAN_PRODUCTS = gql`
  query CustomerPlanProducts($customerUuid: UUID) {
    customerPlanProducts: plansForCustomer(customerUuid: $customerUuid) {
      plan {
        uuid
        name
      }
      product {
        uuid
        name
        hasZones
      }
    }
  }
`;

export const LOCATION_DROPDOWN_DATA = gql`
  query locationDropdownData {
    locationsForUser {
      uuid
      name
    }
    userSetting: getUserSetting {
      uuid
      currentLocation {
        uuid
        name
      }
      user {
        id
        username
      }
    }
  }
`;
export const GET_USER_SETTING = gql`
  query getUserSetting {
    getUserSetting {
      uuid
      currentLocation {
        uuid
        name
      }
      user {
        id
        username
        groups {
          id
          name
        }
      }
    }
  }
`;

export const QUERY_GET_SUBSCRIPTION_CHECKINS = gql`
  query getSubscriptionCheckIn($subscriptionUuid: UUID, $checkInMaxLimit: Int) {
    subscription(subscriptionUuid: $subscriptionUuid) {
      uuid
      startDate
      state
      paymentType
      zonesQuantity
      plan {
        uuid
        name
      }
      customer {
        uuid
        last
        first
        email
        needsUpdate
      }
      location {
        uuid
        name
      }
    }
    subscriptionCheckins(
      subscriptionUuid: $subscriptionUuid
      checkInMaxLimit: $checkInMaxLimit
    ) {
      uuid
      date
      time
      notes
      isPresent
      checkInStatus
      closedDate
      timestamp
      product {
        uuid
        name
      }
      subscription {
        uuid
        state
        paymentType
        plan {
          uuid
          name
        }
        customer {
          uuid
          needsUpdate
        }
      }
      worker {
        uuid
        first
        last
      }
    }
  }
`;

export const SUBSCRIPTION_SCHEDULED_LIST = gql`
  query subscriptionScheduled {
    scheduledSubscriptionList {
      uuid
      scheduledDate
      subscription {
        uuid
        startDate
        paymentType
        state
        scheduledDate
        customer {
          uuid
          last
          first
        }
        invoicingCustomer {
          uuid
          last
          first
        }
        plan {
          uuid
          name
        }
      }
    }
  }
`;

export const SUBSCRIPTION_UNPAID_LIST = gql`
  query subscriptionUnpaidList {
    subscriptionUnpaidList {
      result {
        __typename
        ... on UnpaidSubscription {
          subscription {
            uuid
            startDate
            paymentType
            state
            quantity
            stripeSubscriptionId
            customer {
              uuid
              last
              first
            }
            invoicingCustomer {
              uuid
              email
              stripeId
              last
              first
            }
            plan {
              uuid
              name
              price
            }
          }
          invoiceDateEnd
          attemptedAmount
          reason
          stripeErrorCode
          paymentIntentStatus
          invoiceDateStart
          invoiceCreated
          invoiceChargedAt
          nextPaymentAttempt
          invoiceAttemptCount
          stripeInvoiceId
          stripeCallErrorMessage
        }
      }
      error {
        message
        code
      }
    }
    userSetting: getUserSetting {
      uuid
      currentLocation {
        uuid
        name
      }
      user {
        id
        username
        groups {
          id
          name
        }
      }
    }
  }
`;
export const GET_STRIPE_INVOICE = gql`
  query getStripeInvoice($invoiceId: String, $subscriptionUuid: UUID) {
    getStripeInvoice(invoiceId: $invoiceId) {
      result {
        ... on InvoiceResult {
          dateEnd
          dateStart
          amountDue
          amountPaid
          status
          statusDetail
          chargedAt
          paidAt
          voidedAt
          markedUncollectibleAt
          refundedAmount
          refundedDate
          errorMessage
          attemptCount
          checkinCount
          stripeInvoiceId
          nextPaymentAttempt
          created
          invoiceUuid
          invoiceExpired
        }
      }
      error {
        message
        code
      }
    }
    stripeSubscription(subscriptionUuid: $subscriptionUuid) {
      result {
        ... on StripeSubscription {
          id
          status
          planInterval
          planIntervalCount
          planNickname
          pauseBehavior
          pauseResumesAt
        }
      }
      error {
        message
        code
      }
    }
  }
`;
export const QUERY_GET_STRIPE_SUBSCRIPTION = gql`
  query getStripeSubscription($subscriptionUuid: UUID) {
    stripeSubscription(subscriptionUuid: $subscriptionUuid) {
      result {
        ... on StripeSubscription {
          id
          status
          planInterval
          planIntervalCount
          planNickname
          pauseBehavior
          pauseResumesAt
          paymentMethod {
            id
            paymentType
            paymentVisibleIdentifier
            created
          }
        }
      }
      error {
        message
        code
      }
    }
  }
`;
export const QUERY_GET_SUBSCRIPTION_UPDATE = gql`
  query getSubscriptionUpdate($subscriptionUuid: UUID) {
    subscription(subscriptionUuid: $subscriptionUuid) {
      uuid
      startDate
      notes
      zonesQuantity
      state
      paymentType
      timestamp
      cancelDate
      scheduledDate
      stripeSubscriptionId
      paidState
      plan {
        uuid
        name
        price
        contractUrl
      }
      invoicingCustomer {
        uuid
        last
        first
        email
        taxId
      }
      customer {
        uuid
        last
        first
        email
        needsUpdate
        stripeId
        fcId
      }
      location {
        uuid
        name
      }
    }
    userSetting: getUserSetting {
      uuid
      currentLocation {
        uuid
        name
      }
    }
  }
`;
export const QUERY_GET_CHECK_IN = gql`
  query GetCheckIn($checkinUuid: UUID) {
    checkin(checkinUuid: $checkinUuid) {
      uuid
      date
      time
      productZones
      notes
      isPresent
      checkInStatus
      closedDate
      timestamp
      location {
        uuid
        name
      }
      customer {
        uuid
        first
        last
        taxId
        email
        agreementPrivacyConsent {
          uuid
        }
        agreementLaserTreatment {
          uuid
        }
      }
      subscription {
        uuid
        startDate
        state
        plan {
          uuid
          name
        }
      }
      product {
        uuid
        name
        enabled
        hasZones
        consentLaserIsRequired
      }
      worker {
        uuid
        first
        last
      }
      station {
        uuid
        name
        enabled
      }
      checkinFundingSource {
        uuid
        name
        enabled
      }
      credit {
        uuid
        startDate
        creditType {
          uuid
          name
        }
        plan {
          uuid
          name
        }
      }
    }
    featureFlags: getFeatureFlags {
      name
      enabled
    }
    getInvoiceForCheckin(checkinUuid: $checkinUuid) {
      uuid
      progressiveNumber
      sector
      date
      status
      sdiSentDate
      providerToken
      creditNoteId
      receiptDestinationEmail
      isLast
    }
    userSetting: getUserSetting {
      uuid
      currentLocation {
        uuid
        name
      }
    }
  }
`;
export const QUERY_GET_CREDIT_LIST = gql`
  query getCustomerCreditList($customerUuid: UUID, $listType: CreditListType) {
    customerCreditList(customerUuid: $customerUuid, listType: $listType) {
      credit {
        uuid
        startDate
        sessionQuantity
        zonesQuantity
        sponsor
        notes
        state
        customer {
          uuid
        }
        plan {
          uuid
          name
        }
        creditType {
          uuid
          name
        }
      }
      checkinCount
    }
  }
`;
export const QUERY_GET_CREDIT = gql`
  query getCredit($creditUuid: UUID, $checkInMaxLimit: Int = 100) {
    credit(creditUuid: $creditUuid) {
      uuid
      startDate
      endDate
      sessionQuantity
      zonesQuantity
      sponsor
      notes
      state
      customer {
        uuid
        last
        first
        email
      }
      plan {
        uuid
        name
        shortName
      }
      creditType {
        uuid
        name
      }
      location {
        uuid
        name
      }
    }
    checkInCounter: checkinsForCreditCount(creditUuid: $creditUuid)
    checkInCredit: checkinsForCredit(
      creditUuid: $creditUuid
      checkInMaxLimit: $checkInMaxLimit
    ) {
      uuid
      date
      time
      isPresent
      checkInStatus
      closedDate
      timestamp
      checkinFundingSource {
        uuid
        name
      }
      credit {
        uuid
        plan {
          uuid
          shortName
          name
        }
      }
      product {
        uuid
        name
      }
      worker {
        last
        first
      }
    }
    checkInNotPresentCounter: checkinsForCreditNotPresentCount(
      creditUuid: $creditUuid
    )
    userSetting: getUserSetting {
      uuid
      currentLocation {
        uuid
        name
      }
    }
  }
`;
export const QUERY_LIST_CREDIT_PAGINATOR = gql`
  query getCreditPaginator($page: Int, $pageSize: Int, $parameter: String) {
    creditPaginator(page: $page, pageSize: $pageSize, parameter: $parameter) {
      page
      totPages
      pageSize
      hasNext
      hasPrev
      listCredit: objects {
        checkinCount
        lastCheckin
        credit {
          uuid
          startDate
          endDate
          state
          sessionQuantity
          customer {
            uuid
            fullName
            needsUpdate
          }
          plan {
            name
            uuid
          }
          creditType {
            name
            uuid
            enabled
          }
        }
      }
    }
    paginator: creditPaginator(
      page: $page
      pageSize: $pageSize
      parameter: $parameter
    ) {
      page
      totPages
      pageSize
      hasNext
      hasPrev
    }
    creditTypeList {
      uuid
      enabled
      name
    }
  }
`;
export const QUERY_CREDIT_UPDATE = gql`
  query getUpdateCredit(
    $creditUuid: UUID
    $checkInMaxLimit: Int = 300
    $enabled: Boolean = true
    $disabled: Boolean = false
  ) {
    credit(creditUuid: $creditUuid) {
      uuid
      startDate
      endDate
      sessionQuantity
      zonesQuantity
      sponsor
      notes
      state
      customer {
        uuid
        last
        first
        email
      }
      plan {
        uuid
        name
        shortName
        disabled
      }
      creditType {
        uuid
        name
        enabled
      }
      location {
        uuid
        name
      }
    }
    checkInCounter: checkinsForCreditCount(creditUuid: $creditUuid)
    lastCheckInCredit: checkinsForCredit(
      creditUuid: $creditUuid
      checkInMaxLimit: $checkInMaxLimit
    ) {
      uuid
      date
      time
      checkinFundingSource {
        uuid
        name
      }
      credit {
        uuid
        plan {
          uuid
          shortName
          name
        }
      }
      product {
        uuid
        name
      }
      worker {
        last
        first
      }
    }
    creditTypeList(enabled: $enabled) {
      uuid
      enabled
      name
    }
    planList: planForCreditList(disabled: $disabled) {
      uuid
      name
      shortName
      disabled
    }
    checkInNotPresentCounter: checkinsForCreditNotPresentCount(
      creditUuid: $creditUuid
    )
    userSetting: getUserSetting {
      uuid
      currentLocation {
        uuid
        name
      }
    }
  }
`;
export const QUERY_DATA_FOR_NEW_CREDIT = gql`
  query getDataForNewCredit(
    $customerUuid: UUID
    $enabled: Boolean = true
    $disabled: Boolean = false
  ) {
    customer(customerUuid: $customerUuid) {
      uuid
      first
      last
      email
    }
    creditTypeList(enabled: $enabled) {
      uuid
      enabled
      name
    }
    planList: planForCreditList(disabled: $disabled) {
      uuid
      name
      shortName
      disabled
    }
  }
`;
export const QUERY_GET_CHECK_IN_OPEN = gql`
  query getOpenCheckins {
    openCheckins {
      uuid
      date
      time
      notes
      checkInStatus
      closedDate
      timestamp
      location {
        uuid
        name
      }
      customer {
        uuid
        first
        last
        taxId
        email
      }
      worker {
        uuid
        first
        last
      }
      station {
        uuid
        name
        enabled
      }
      product {
        uuid
        name
        enabled
        hasZones
      }
      checkinFundingSource {
        uuid
        name
        enabled
      }
      subscription {
        uuid
        startDate
        state
        plan {
          uuid
          name
        }
      }
      credit {
        uuid
        startDate
        creditType {
          uuid
          name
        }
        plan {
          uuid
          name
        }
      }
    }
  }
`;

// Lead
export const LEAD_SEARCH = gql`
  query leadSearch(
    $searchQuery: String
    $includedLocationUuids: [UUID]
    $page: Int
    $pageSize: Int
    $includedActivityTypes: [LeadActivityTypeEnum!]
    $excludedActivityTypes: [LeadActivityTypeEnum!]
  ) {
    leadSearch(
      searchQuery: $searchQuery
      includedLocationUuids: $includedLocationUuids
      page: $page
      pageSize: $pageSize
      includedActivityTypes: $includedActivityTypes
      excludedActivityTypes: $excludedActivityTypes
    ) {
      objects {
        uuid
        email
        fullName
        phoneNumber
        creationDate
        similarity
        location {
          uuid
          name
        }
      }
      page
      pageSize
      hasNext
    }
  }
`;

export const GET_LEAD_AND_ACTIVITIES_BY_UUID = gql`
  query getLeadAndActivities($uuid: UUID!) {
    getLeadByUuid(uuid: $uuid) {
      uuid
      email
      fullName
      phoneNumber
      campaignName
      adSetName
      adName
      creationDate
      location {
        uuid
        name
      }
      originalLocation {
        uuid
        name
      }
      source {
        uuid
        name
      }
      owner {
        id
        username
      }
      matchingLeads {
        uuid
        email
        phoneNumber
        creationDate
      }
      matchingCustomers {
        uuid
        email
        phoneNumber
        fullName
        timestamp
      }
    }
    leadActivityList(leadUuid: $uuid) {
      uuid
      activityType
      creationDate
      autoGenerated
      notes
      payload
      lead {
        uuid
        email
      }
      appointment {
        startDatetime
        calendarName
      }
      owner {
        id
        username
      }
      enabled
    }
  }
`;

export const LOCATIONS_LIST = gql`
  query locationList {
    locationList {
      uuid
      name
    }
  }
`;
export const QUERY_GET_PREPAID_PACKAGE = gql`
  query getPrepaidPackage(
    $prepaidPackageuuid: UUID
    $checkInMaxLimit: Int = 100
  ) {
    getPrepaidPackage(prepaidPackageUuid: $prepaidPackageuuid) {
      uuid
      startDate
      endDate
      sessionQuantity
      notes
      state
      customer {
        uuid
        last
        first
        email
        taxId
      }
      invoicingCustomer {
        uuid
        last
        first
        email
        taxId
      }
      packagePlan {
        uuid
        description
        shortName
        price
        name
        product {
          uuid
          name
        }
      }
      location {
        uuid
        name
      }
    }
    checkInCounter: checkinsForPrepaidPackageCount(
      prepaidPackageUuid: $prepaidPackageuuid
    )
    checkInPrepaidPackage: checkinsForPrepaidPackage(
      prepaidPackageUuid: $prepaidPackageuuid
      checkInMaxLimit: $checkInMaxLimit
    ) {
      uuid
      date
      time
      isPresent
      checkInStatus
      closedDate
      timestamp
      checkinFundingSource {
        uuid
        name
      }
      prepaidPackage {
        uuid
        packagePlan {
          uuid
          description
          shortName
        }
      }
      product {
        uuid
        name
      }
      worker {
        last
        first
      }
    }
    checkInNotPresentCounter: checkinsForPrepaidPackageNotPresentCount(
      prepaidPackageUuid: $prepaidPackageuuid
    )
    receipt: getReceiptForPrepaidPackage(
      prepaidPackageUuid: $prepaidPackageuuid
    ) {
      uuid
      stripePaymentIntentId
      amount
      creditNoteId
      date
      status
      invoice {
        uuid
        date
      }
    }
    creditNote: getCreditNoteForPrepaidPackage(
      prepaidPackageUuid: $prepaidPackageuuid
    ) {
      uuid
      date
      amountRefunded
      stripePaymentIntentId
    }
    userSetting: getUserSetting {
      uuid
      currentLocation {
        uuid
        name
      }
    }
  }
`;

export const DAYLY_SALES_REPORT_LIST = gql`
  query GetDailySalesReportList($dateParam: Date = null) {
    dailySalesReportList(dateParam: $dateParam) {
      year
      month
      day
      vatRate
      dailyTotalAmountReceipts
      dailyTotalAmountRefunds
      dailyTotalAmountInvoices
      notes
      grossAmount
      netAmount
      vat
    }
    userSetting: getUserSetting {
      uuid
      currentLocation {
        uuid
        name
      }
      user {
        id
        username
        groups {
          id
          name
        }
      }
    }
  }
`;
export const VERIFY_FISCAL_CODE = gql`
  query verifyFiscalCode($fiscalCode: String!) {
    verifyFiscalCode(fiscalCode: $fiscalCode) {
      valid
      message
      error
      statusCode
    }
    featureFlags: getFeatureFlags {
      name
      enabled
    }
  }
`;
export const GET_FEATURE_FLAGS = gql`
  query FeatureFlagByName($name: String!) {
    featureFlagByName(name: $name) {
      name
      enabled
    }
  }
`;
