import CustomerFindForm from "../components/CustomerSearchForm";
import React from "react";
import { getCustomerUrl } from "../api/urls";
import { useNavigate } from "react-router-dom";

const columns = [
  {
    header: "Cognome",
    accessorKey: "last",
    enableColumnFilter: true,
    filterFn: 'fuzzy',
    enableSorting: true,
  },
  {
    header: "Nome",
    accessorKey: "first",
    enableColumnFilter: true,
    filterFn: 'fuzzy',
    enableSorting: true,
  },
  {
    header: "Email",
    accessorKey: "email",
    enableColumnFilter: true,
    filterFn: 'fuzzy',
    enableSorting: true,
  },
  {
    header: "Codice Fiscale",
    accessorKey: "taxId",
    enableColumnFilter: true,
    filterFn: 'includesString',
    enableSorting: true,
  },
  {
    header: "Telefono",
    accessorKey: "phoneNumber",
    enableColumnFilter: true,
    filterFn: 'fuzzy',
    enableSorting: true,
  },
  {
    header: "Sede",
    accessorKey: "location.name",
    enableColumnFilter: true,
    filterFn: 'includesString',
    enableSorting: true,
  },
  {
    header: "Data Nascita",
    accessorKey: "dob",
    enableColumnFilter: false,
    enableSorting: true,
  },
];

function CustomerSearchPage() {
  const data = [];
  const navigate = useNavigate();

  function onCreatedCustomer(customer) {
    navigate(getCustomerUrl(customer?.uuid));
  }

  const openCustomer = (customer, openInNewTab = false) => {
    if (openInNewTab) {
      window.open(`/customers/${customer.uuid}`, '_blank');
    } else {
      navigate(getCustomerUrl(customer.uuid));
    }
  };

  return (
    <CustomerFindForm
      data={data}
      columns={columns}
      openCustomer={openCustomer}
      onCreatedCustomer={onCreatedCustomer}
    />
  );
}

export default CustomerSearchPage;
