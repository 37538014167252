import "./App.css";

import {
  getCustomerUrl,
  getPlanUrl,
  getSubscriptionCheckinsListUrl,
  getSubscriptionDetailUrl,
  getSubscriptionInvoicesListUrl,
  getSubscriptionStripeUrl,
} from "../api/urls";

import ActionsButton from "../components/ActionsButton";
import CustomerSubscriptionsList from "../components/CustomerSubscriptionsList";
import { ErrorMessage } from "../components/ErrorMessage";
import { QUERY_CUSTOMER } from "../api/queries";
import React from "react";
import StateIcon from "../components/StateIcon";
import constants from "../constants";
import { isUserInGroup } from "../api/utils";
import styled from "@emotion/styled";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";

// Styles
const EmojiSpan = styled.span`
  font-size: 1.5rem;
`;

function loadListSubscriptions(
  customerSubscriptions,
  customerSubscriptionsInvoicing,
  isManagementStripeMenu
) {
  let listSubscription = customerSubscriptions.map((s) => {
    const subscriptionStateIcon = (
      <StateIcon nameIcon={s?.state} objType="Icon"></StateIcon>
    );

    const unpaidState =
      s?.paidState === constants.SUBSCRIPTION_STATEPAID_UNPAID ? (
        <EmojiSpan title={`Ci sono rate non pagate`}>
          <span role="img" aria-label="cash">
            ⚠️
          </span>
        </EmojiSpan>
      ) : null;

    return {
      uuid: s.uuid,
      startDate: s?.startDate,
      paymentType: s?.paymentType,
      stateSubscription: subscriptionStateIcon,
      customerFullName: null,
      planShortName: s?.plan?.shortName,
      planName: s?.plan?.name,
      paidState: unpaidState,
      stripeSubscriptionId: s.stripeSubscriptionId,
      planUuid: s?.plan?.uuid,
      customerUuid: s?.customer?.uuid,
      stateSub: s?.state,
      isManagementStripeMenu: isManagementStripeMenu,
    };
  });

  for (let k in customerSubscriptionsInvoicing) {
    const {
      uuid,
      startDate,
      paymentType,
      state: stateSubscription,
      customer,
      plan,
      paidState,
      stripeSubscriptionId,
      stateSub,
    } = customerSubscriptionsInvoicing[k];
    const subState = (
      <StateIcon nameIcon={stateSubscription} objType="Icon"></StateIcon>
    );
    const unpaidState =
      paidState === constants.SUBSCRIPTION_STATEPAID_UNPAID ? (
        <EmojiSpan title={`Ci sono rate non pagate`}>
          <span role="img" aria-label="cash">
            ⚠️
          </span>
        </EmojiSpan>
      ) : null;
    listSubscription.push({
      uuid,
      startDate,
      paymentType,
      stateSubscription: subState,
      customerFullName: customer?.fullName,
      planShortName: plan?.shortName,
      planName: plan?.name,
      paidState: unpaidState,
      stripeSubscriptionId,
      planUuid: plan?.uuid,
      customerUuid: customer?.uuid,
      stateSub,
      isManagementStripeMenu: isManagementStripeMenu,
    });
  }
  // Sort for dateStart descendig
  listSubscription.sort(function (a, b) {
    return new Date(b.startDate) - new Date(a.startDate);
  });
  return listSubscription;
}

const popoverLinksList = (cell) => {
  let linksList = [];
  const showListPayment =
    cell?.paymentType?.toUpperCase() === constants.STRIPE_PAYMENT_METHOD;
  const isPaymentScheduled =
    cell?.stateSub === constants.SUBSCRIPTION_STATE_SCHEDULED;
  const showCustomerDetail = !!cell?.customerFullName;
  if (showCustomerDetail) {
    linksList.push({
      title: "Visualizza Cliente Beneficiario",
      url: getCustomerUrl(cell?.customerUuid),
    });
  }
  if (showListPayment && !isPaymentScheduled) {
    linksList.push(
      ...[
        {
          title: "Visualizza Elenco Pagamenti",
          url: getSubscriptionInvoicesListUrl(cell?.uuid),
        },
      ]
    );
  }

  linksList = [
    ...linksList,
    {
      title: "Visualizza Abbonamento",
      url: getSubscriptionDetailUrl(cell?.uuid),
    },
    { title: "Visualizza Piano", url: getPlanUrl(cell?.planUuid) },
    {
      title: "Visualizza Elenco Check-In",
      url: getSubscriptionCheckinsListUrl(cell?.uuid),
    },
  ];

  if (cell.isManagementStripeMenu) {
    linksList.splice(1, 0, {
      title: "Visualizza Abbon. STRIPE",
      url: getSubscriptionStripeUrl(cell?.subscriptionStripeId),
    });
  }

  return linksList;
};

function CustomerSubscriptionsListPage() {
  const columns = React.useMemo(
    () => [
      {
        Header: () => (
          <div
            style={{
              textAlign: "center",
            }}
          >
            Data Abbonamento
          </div>
        ),
        accessor: "startDate",
        width: 45,

        Cell: ({ cell }) => (
          <div>
            {cell?.row?.original.stateSubscription}
            <span>&nbsp;</span>
            {cell?.row?.original.startDate}
            <span>&nbsp;&nbsp;</span>
            {cell?.row?.original.paidState}{" "}
          </div>
        ),
      },
      {
        Header: "Beneficiario",
        accessor: "customerFullName",
        width: 50,
      },
      {
        Header: "Piano",
        accessor: "planShortName",
        width: 65,
        Cell: ({ cell }) => <div>{cell?.row?.original.planName}</div>,
      },
      {
        Header: "Tipo Pagamento",
        accessor: "paymentType",
        width: 65,
        Cell: ({ cell }) => <div>{cell?.row?.original.paymentType}</div>,
      },
      {
        Header: "",
        accessor: "menu",
        width: 15,
        Cell: ({ cell }) => {
          const links = popoverLinksList(cell?.row?.original);
          return <ActionsButton linksList={links} />;
        },
      },
    ],
    []
  );

  const LIMIT_MAX_RECORD = 0;

  let { customerUuid } = useParams();

  const { loading, error, data } = useQuery(QUERY_CUSTOMER, {
    variables: {
      customerUuid: customerUuid,
      checkInMaxLimit: LIMIT_MAX_RECORD,
    },
  });

  if (loading) {
    return <p>Loading..</p>;
  }
  if (error) {
    return <ErrorMessage errors={[error]} />;
  }

  const isManagementStripeMenu = isUserInGroup(
    data?.userSetting,
    constants.GROUPS_MANAGEMENT_STRIPE_MENU
  );

  const subscriptionsList = loadListSubscriptions(
    data?.customerSubscriptions,
    data?.customerSubscriptionsInvoicing,
    isManagementStripeMenu
  );

  return (
    <>
      <CustomerSubscriptionsList
        columns={columns}
        subscriptionsList={subscriptionsList}
        customer={data?.customer}
      />
    </>
  );
}

export default CustomerSubscriptionsListPage;
