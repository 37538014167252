import Button from "react-bootstrap/Button";
import { ErrorMessage } from "./ErrorMessage";
import React from "react";
const paymentTypeMapping = {
  STRIPE: "Stripe",
  PAGODIL: "PagoDIL",
};

function subscriptionToContractLink(subscription) {
  // Mapping
  const params = {
    nome: subscription.first,
    cognome: subscription.last,
    codice_fiscale: subscription.taxId,
    email: subscription.email,
    tipo_pagamento: paymentTypeMapping[subscription.paymentType] || "",
    sede: subscription.locationName,
    importo: subscription.amount.toString().replace(".", ","),
    uuid: subscription.subscriptionUuid,
    invoicing_uuid: subscription.invoicingCustomerUuid,
  };
  const url = new URL(subscription.agreementContract);
  for (const [key, value] of Object.entries(params)) {
    if (value !== null) url.searchParams.append(key, value);
  }

  return url.toString();
}
function subscriptionToCancelContractLink(subscription) {
  // Mapping
  const params = {
    nome: subscription.first,
    cognome: subscription.last,
    codice_fiscale: subscription.taxId,
    email: subscription.email,
    sede: subscription.locationName,
    importo: subscription.amount.toString().replace(".", ","),
    uuid: subscription.subscriptionUuid,
    data_sottoscrizione: subscription.startDate,
    tipo_abbonamento: subscription.typeSubscription,
    sessioni: subscription.numberOfSession,
    invoicing_uuid: subscription.invoicingCustomerUuid,
    sede_nome:subscription.locationName,
  };

  const url = new URL(subscription.agreementContract);
  for (const [key, value] of Object.entries(params)) {
    if (value !== null) url.searchParams.append(key, value);
  }
  return url.toString();
}

function ContractLink({ subscription }) {
  if (subscription?.signatureFormType === "NewContract") {
    if (!subscription?.agreementContract) {
      const ERROR_NO_PLAN =
        "Il piano utilizzato non ha un contratto disponibile";
      return <ErrorMessage errors={[ERROR_NO_PLAN]} />;
    }
    const contractUrl = subscriptionToContractLink(subscription);
    return (
      <Button variant="primary" href={contractUrl}>
        Firma contratto
      </Button>
    );
  }
  if (subscription?.signatureFormType === "CancelContract") {
    if (!subscription?.agreementContract) {
      const ERROR_NO_PLAN =
        "Il piano utilizzato non ha un modulo per la disdetta abbonamento disponibile";
      return <ErrorMessage errors={[ERROR_NO_PLAN]} />;
    }
    const contractUrl = subscriptionToCancelContractLink(subscription);
    return (
      <Button variant="primary" href={contractUrl}>
        Firma disdetta
      </Button>
    );
  }
}

export { ContractLink, subscriptionToContractLink };
